// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HKG2oKH0QlOxf0Z9_pyTh{animation:_2WuP0SYdQskJv6yUBpd8ih .6s linear infinite}@-moz-keyframes _2WuP0SYdQskJv6yUBpd8ih{from{transform:rotate(0)}to{transform:rotate(360deg)}}@-webkit-keyframes _2WuP0SYdQskJv6yUBpd8ih{from{transform:rotate(0)}to{transform:rotate(360deg)}}@-o-keyframes _2WuP0SYdQskJv6yUBpd8ih{from{transform:rotate(0)}to{transform:rotate(360deg)}}@keyframes _2WuP0SYdQskJv6yUBpd8ih{from{transform:rotate(0)}to{transform:rotate(360deg)}}", "",{"version":3,"sources":["webpack://./src/ui/Spinner/styles.styl"],"names":[],"mappings":"AAAA,uBAAS,qDAAkC,CAAC,wCAAqB,KAAK,mBAAmB,CAAC,GAAG,wBAAwB,CAAC,CAAC,2CAAwB,KAAK,mBAAmB,CAAC,GAAG,wBAAwB,CAAC,CAAC,sCAAmB,KAAK,mBAAmB,CAAC,GAAG,wBAAwB,CAAC,CAAC,mCAAgB,KAAK,mBAAmB,CAAC,GAAG,wBAAwB,CAAC","sourcesContent":[".spinner{animation:spin .6s linear infinite}@-moz-keyframes spin{from{transform:rotate(0)}to{transform:rotate(360deg)}}@-webkit-keyframes spin{from{transform:rotate(0)}to{transform:rotate(360deg)}}@-o-keyframes spin{from{transform:rotate(0)}to{transform:rotate(360deg)}}@keyframes spin{from{transform:rotate(0)}to{transform:rotate(360deg)}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": "HKG2oKH0QlOxf0Z9_pyTh",
	"spin": "_2WuP0SYdQskJv6yUBpd8ih"
};
export default ___CSS_LOADER_EXPORT___;
