import styled from 'styled-components';

type ButtonVariant = 'primary' | 'secondary';

export const StyledButton = styled.button<{ variant: ButtonVariant }>`
  padding: 14px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 4px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  color: ${props => getColorByVariant(props.variant)};
  background-color: ${props => getBgColorByVaraint(props.variant)};
  border: ${props => getBorderByVaraint(props.variant)};

  box-shadow: ${props => getShadowByVariant(props.variant)};

  :hover {
    opacity: 0.9;
  }
`;

export const TransparentButton = styled.button`
  color: ${({ theme }) => theme.palette.Primary};
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: none;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;

  :hover,
  :focus {
    opacity: 0.8;
  }
`;

function getColorByVariant(variant: ButtonVariant) {
  const map: Record<ButtonVariant, string> = {
    primary: 'white',
    secondary: 'hsl(219, 99%, 53%)'
  };

  return map[variant];
}

function getBgColorByVaraint(variant: ButtonVariant) {
  const map: Record<ButtonVariant, string> = {
    primary: 'hsl(219, 99%, 53%)',
    secondary: 'transparent'
  };

  return map[variant];
}

function getBorderByVaraint(variant: ButtonVariant) {
  const map: Record<ButtonVariant, string> = {
    primary: '1px solid hsl(219, 99%, 53%)',
    secondary: '1px solid hsla(219, 99%, 53%, 0.2)'
  };

  return map[variant];
}

function getShadowByVariant(variant: ButtonVariant) {
  const map: Record<ButtonVariant, string> = {
    primary: '0px 4px 12px 0px hsla(219, 99%, 53%, 0.3)',
    secondary: 'none'
  };

  return map[variant];
}
