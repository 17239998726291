import * as React from 'react';
import styled from 'styled-components';
import { DropableArea } from 'components/DropableArea';
import { concatFilesWithoutDoubles } from './utils';

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

type DivProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

interface FileInputProps extends Omit<DivProps, 'onChange' | 'onDrop'> {
  value: File[];
  onChange: (files: File[]) => void;
  inputProps?: InputProps; // temporarily workaround
  onDrop?: (files: File[]) => void;
}

export const FileInput = React.forwardRef<HTMLInputElement, FileInputProps>(
  function FileInput(
    {
      value = [],
      onChange,
      inputProps,
      children,
      onDrop = onChange,
      ...wrapperProps
    },
    forwardedRef,
  ) {
    return (
      <DropableArea
        onDrop={e => {
          const files = Array.from(e.dataTransfer.files) as File[];
          if (onDrop instanceof Function) {
            onDrop(concatFilesWithoutDoubles(value, files));
          }
        }}
        {...wrapperProps}
      >
        {children}

        <HiddenInput
          ref={forwardedRef}
          type="file"
          onChange={e => {
            const files = Array.from(e.target.files) as File[];
            onChange(concatFilesWithoutDoubles(value, files));

            // clear input value
            e.target.value = '';
          }}
          {...(inputProps as any)} // temporarily workaround
        />
      </DropableArea>
    );
  },
);

const HiddenInput = styled.input`
  display: none;
`;
