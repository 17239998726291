import * as React from 'react';
import { Button } from '@we-ui-components/base';
import { Spinner2 } from './Spinner2';

export const ButtonEx = ({
  textRender = null,
  transparent,
  spinnerRender = (
    <Spinner2
      height="20px"
      width="20px"
      color={transparent ? 'Black' : 'White'}
      style={{ marginRight: 5 }}
    />
  ),
  isLoading,
  children,
  onClick,
  accept,
  ...buttonProps
}: ButtonExProps) => {
  return (
    <Button
      onClick={isLoading ? () => {} : onClick}
      transparent={transparent}
      {...buttonProps}
    >
      {isLoading ? spinnerRender : textRender}
      {children}
    </Button>
  );
};

export type ButtonExProps = React.ComponentProps<typeof Button> & {
  textRender?: React.ReactNode;
  spinnerRender?: React.ReactNode;
  children?: React.ReactNode;
  accept?: string;
  onClick?: (event?: any) => void | Promise<void>;
};
