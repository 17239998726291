// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2AojO7srwM8dEwTIrTh4Qo{cursor:pointer;opacity:1}._2AojO7srwM8dEwTIrTh4Qo:hover{opacity:.6}", "",{"version":3,"sources":["webpack://./src/ui/Icons/styles.styl"],"names":[],"mappings":"AAAA,yBAAW,cAAc,CAAC,SAAS,CAAC,+BAAiB,UAAU","sourcesContent":[".hoverIcon{cursor:pointer;opacity:1}.hoverIcon:hover{opacity:.6}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hoverIcon": "_2AojO7srwM8dEwTIrTh4Qo"
};
export default ___CSS_LOADER_EXPORT___;
