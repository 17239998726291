import { IStores } from 'stores/index';
import { ListStoreConstructor } from 'stores/core/ListStoreConstructor';
import { PollApiDto, PollCreateRequest } from 'interfaces/apiEntities';
import * as pollsService from 'services/polls';

export class PollListStore extends ListStoreConstructor<PollApiDto> {
  constructor(stores: IStores) {
    super(stores, pollsService.getPollList, { paginationData: { pageSize: 100 }});
  }

  public async createPoll(newPollData: PollCreateRequest) {
    return pollsService.createPoll(newPollData);
  }

  public async editPoll(pollId: string, pollData: PollCreateRequest) {
    const response = await pollsService.editPoll(pollId, pollData);
    const index = this.data.findIndex(it => response.id === it.id);

    if (index >= 0) {
      this.allData[index] = response;
    }
  }
}
