import { FieldWrapper } from '@we-ui-components/base';
import { createField } from '@we-ui-components/form';
import * as React from 'react';

import { ViolationFileUploader, IFileLimits } from './uploader';
import { ViolationFileInputList } from './file-list';

export interface ViolationFileInputProps {
  value: File[];
  onChange: (fileIds: File[]) => void;
  formats: string[],
  limits?: IFileLimits,
  withPreview?: boolean;
}

export const FilesIdInput = createField<any>({
  component: props => <FileInputWrap {...props} />,
  wrapper: FieldWrapper
});

class FileInputWrap extends React.Component<ViolationFileInputProps> {
  onChange = (files: File[]) => {
    const { limits } = this.props;
    const { maxFiles = 0 } = limits || {};
    if(!!maxFiles) {
      this.props.onChange(files.slice(0, maxFiles));
    } else {
      this.props.onChange(files);
    }
  };

  render() {
    return (
      <>
        <ViolationFileUploader {...this.props} onChange={this.onChange} />
        <ViolationFileInputList {...this.props} onChange={this.onChange} />
      </>
    );
  }
}
