import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores';
import { Card, DateRange, TransparentButton } from 'ui';
import { Text, Button } from '@we-ui-components/base';
import { PollApiDto, PollCreateRequest } from 'interfaces/apiEntities';
import { Box, BoxProps } from 'grommet';
import { EditPollForm } from 'modalPages/EditPollForm';
import { StyledButton } from 'ui/StyledButton';

export const AboutPoll = observer<BoxProps>(function AboutPollInof(props) {
  const [error, setError] = useState('');
  const { activePoll, actionModals } = useStores();
  const { poll } = activePoll;

  const handleClick = () => {
    actionModals.open(EditPollForm, {
      title: 'Редактирование голосования',
      applyText: () => <span>Сохранить</span>,
      closeText: () => <span>Отменить</span>,
      async onApply(data: PollCreateRequest) {
        return activePoll.editPoll(data);
      },
      initData: poll,
      noValidation: true
    });
  };

  const publishPoll = () => {
    setError('');
    activePoll.publishPoll().then(() => window.location.reload()).catch(err => {
      setError(err.detail);
    });
  };

  return (
    <Box align="end" gap="medium">
      <Card
        style={{ position: 'relative' }}
        height={{ max: '290px' }}
        pad="32px"
        {...props}
      >
        {activePoll.isEditable ? (
          <TransparentButton
            onClick={handleClick}
            style={{ position: 'absolute', top: '32px', right: '32px' }}
          >
            <img src="/icons/edit-icon.svg" />
          </TransparentButton>
        ) : null}

        <Text fontWeight="700">О голосовании</Text>

        <DateRange
          margin={{ top: '1em' }}
          startDate={poll.votingStartDate}
          endDate={poll.votingEndDate}
        />

        {poll.description ? <Description poll={poll} /> : null}
      </Card>
      {activePoll.isEditable && (
        <StyledButton
          variant="secondary"
          style={{ width: '100%' }}
          onClick={publishPoll}
        >
          Опубликовать
        </StyledButton>
      )}
      {!!error && (
        <Text size="small" color="Red" style={{ maxWidth: '350px' }}>{error}</Text>
      )}
    </Box>
  );
});

type DescriptionProps = {
  poll: PollApiDto;
};

function Description({ poll }: DescriptionProps) {
  const TRUNC_LEN = 200;

  const { actionModals } = useStores();

  const desctiptionPreview = truncateString(poll.description, TRUNC_LEN);

  const handleCLick = () => {
    actionModals.open(
      () => (
        <Box>
          <Text>{poll.description}</Text>
        </Box>
      ),
      {
        title: 'О голосовании',
        onApply: async () => {
          // noop
        },
        applyText: () => <span>Закрыть</span>,
        noValidation: true
      }
    );
  };

  return (
    <Box align="start">
      <Text margin={{ top: '1em' }}>{desctiptionPreview}</Text>
      {poll.description.length > TRUNC_LEN ? (
        <TransparentButton onClick={handleCLick} style={{ marginTop: '1em' }}>
          Читать полностью
        </TransparentButton>
      ) : null}
    </Box>
  );
}

function truncateString(str: string, len: number) {
  if (str.length > len) {
    return str.slice(0, len).replace(/\.?\s\S+$/, '') + '...';
  }

  return str;
}
