import { createQS } from 'we-oauth2/lib/auth/api-utils';

const nodeEnv = process.env.NODE_ENV;
export const baseUrl =
  nodeEnv === 'production' ? window.location.origin : process.env.BASE_URL;

const getApiUrl = () => `${baseUrl}/api/v0`;
const getVoteApiUrl = () => `${getApiUrl()}/we-vote-saas`;

export const ENDPOINTS = {
  //usual
  transaction: (id: string) => `${getVoteApiUrl()}/tx-track-info/${id}`,
  token: () => `${getApiUrl()}/vst-oauth2/oauth/token`,
  registration: () => `${getApiUrl()}/registration/register`,
  confirmRegistration: () => `${getApiUrl()}/registration/confirm`,
  accounts: () => `${getApiUrl()}/vst-oauth2/accounts`,
  account: (id: string) => `${getApiUrl()}/vst-oauth2/accounts/${id}`,
  userInfo: () => `${getApiUrl()}/vst-identity/persons/info`,
  logout: () => `${getApiUrl()}/logout`,
  companies: () => `${getVoteApiUrl()}/vst-identity/companies`,
  businessRoles: () => `${getVoteApiUrl()}/vst-identity/business-roles`,
  companyTypes: () => `${getVoteApiUrl()}/vst-identity/company-types`,

  //business
  ipObjectList: () => `${getVoteApiUrl()}/ip-objects`,
  ipObject: (id: string) => `${getVoteApiUrl()}/ip-objects/${id}`,
  violations: () => `${getVoteApiUrl()}/violations`,
  violationsDuplicates: (id: string) =>
    `${ENDPOINTS.violations()}/${id}/duplicates`,
  violation: (id: string) => `${ENDPOINTS.violations()}/${id}`,
  violationAction: (id: string, action: string) =>
    `${ENDPOINTS.violations()}/${id}/${action}`,
  expertSolvers: () => `${getVoteApiUrl()}/experts/solvers`,

  //voting
  vote: () => `${getVoteApiUrl()}/polls`,

  districts: () => `${getVoteApiUrl()}/poll-subjects`,
  districtsWithVotes: () => `${getVoteApiUrl()}/school-districts`,
  schools: () => `${getVoteApiUrl()}/schools`,
  techWindow: () => `${getVoteApiUrl()}/polls/settings`,

  // polls
  allPolls: () => `${getVoteApiUrl()}/poll-subjects`,
  pollById: (id: string) => `${ENDPOINTS.vote()}/${id}`,
  publishPollById: (id: string) => `${ENDPOINTS.vote()}/${id}/publish`,
  pollResults: (id: string) => `${ENDPOINTS.vote()}/${id}/results`,
  archivePolls: () => `${getVoteApiUrl()}/public/archive/polls`,
  archivePoll: (id: string) => `${ENDPOINTS.archivePolls()}/${id}`,
  archiveProjects: () => `${getVoteApiUrl()}/public/archive/projects`,

  // projects
  projects: () => `${getVoteApiUrl()}/projects`,
  reworkProjects: (id: string) =>
    `${getVoteApiUrl()}/projects/${id}/completeRework`,
  projectsCount: () => `${ENDPOINTS.projects()}/count`,
  projectsCountTab: (id: string) => `${ENDPOINTS.vote()}/${id}/statistics`,
  projectById: (id: string) => `${getVoteApiUrl()}/projects/${id}`,
  projectLogo: (id: string) => `${getVoteApiUrl()}/public/projects/${id}/logo`,
  exportProjects: () => `${ENDPOINTS.projects()}/export`,

  rateProject: (id: string) => `${ENDPOINTS.projectById(id)}/score`,

  projectAction: (id: string, action: string) =>
    `${getVoteApiUrl()}/projects/${id}/${action}`,

  files: () => `${getVoteApiUrl()}/files`,

  // TODO: temporarily removed token in qs
  getProjectFile: (fileId: string, query?: object) =>
    `${getVoteApiUrl()}/project-files/${fileId}`,

  getPublicProjectFile: (fileId: string, query?: object) =>
    `${getVoteApiUrl()}/public/archive/project-files/${fileId}` +
    createQS({ ...query }),

  getConfidentialFile: (query?: object) =>
    `${getVoteApiUrl()}/public/policy-files/current` + createQS({ ...query }),

  getLegalDocuments: () => `${getVoteApiUrl()}/public/legal-documents/`,

  getLegalDocumentsFile: (fileId: string, params: object = { inline: true }) =>
    `${getVoteApiUrl()}/public/legal-documents/${fileId}` +
    createQS({ ...params }),

  phoneRegister: (phoneNumber, captcha) =>
    `${getVoteApiUrl()}/public/auth/request-phone-code?phoneNumber=${phoneNumber}&g-recaptcha-response=${captcha}`,
  sendConfirmCode: () => `${getVoteApiUrl()}/vst-oauth2/oauth/token`,

  encryptedContent: () => `${getVoteApiUrl()}/encrypted-content`,

  getMainKey: (pollId: string) => `${getVoteApiUrl()}/polls/${pollId}/mainKey`,
  getSignKey: (pollId: string) => `${getVoteApiUrl()}/polls/${pollId}/signKey`,
  getVote: (pollId: string) => `${getVoteApiUrl()}/polls/${pollId}/vote`,
  sendBulletin: (pollId: string) =>
    `${getVoteApiUrl()}/polls/${pollId}/bulletins`,

  updateAccount: (personId: string) =>
    `${getVoteApiUrl()}/users/${personId}/account`,
  updatePersonPartial: (personId: string) =>
    `${getVoteApiUrl()}/users/${personId}/partial`,
  updatePersonFull: (personId: string) =>
    `${getVoteApiUrl()}/users/${personId}/full`,

  // users
  users: () => `${getVoteApiUrl()}/users`,
  getUser: (id: string) => `${ENDPOINTS.users()}/${id}`,
  updateLoginPassUser: (id: string) => `${ENDPOINTS.getUser(id)}/account`,
  changePassword: (id: string) => `${ENDPOINTS.getUser(id)}/password`,
  activateUser: (id: string) => `${ENDPOINTS.getUser(id)}/activate`,
  blockUser: (id: string) => `${ENDPOINTS.getUser(id)}/block`,

  ...getPollControllerEndpoints(),
  ...getQuestionsControllerEndpoints(),
  ...getOptionsControllerEndpoints()
};

function getPollControllerEndpoints() {
  const base = `${getVoteApiUrl()}/polls`;
  const byId = (pollId: string) => `${base}/${pollId}`;

  return {
    polls: () => base,
    pollById: byId,
    publishPoll: (id: string) => `${byId(id)}/publish`,
    pollByAlias: (alias: string) => `${base}/aliases/${alias}`
  };
}

function getQuestionsControllerEndpoints() {
  const byPollId = (id: string) =>
    `${getPollControllerEndpoints().pollById(id)}/questions`;

  return {
    questions: (pollId: string) => byPollId(pollId),
    questionByNum: (num: number, pollId: string) => `${byPollId(pollId)}/${num}`
  };
}

function getOptionsControllerEndpoints() {
  const questions = (pollId: string) =>
    getQuestionsControllerEndpoints().questions(pollId);

  const base = (pollId: string, num: number) =>
    `${questions(pollId)}/${num}/options`;

  // for some reasons it doesnt work without the slash
  const options = (pollId: string, num: number) => `${base(pollId, num)}/`;

  const optionByNum = (pId: string, qNum: number, num: number) =>
    `${base(pId, qNum)}/${num}`;

  const optionFile = (pId: string, qNum: number, num: number) =>
    `${optionByNum(pId, qNum, num)}/file`;

  return {
    options,
    optionByNum,
    optionFile
  };
}
