import * as React from 'react';
import { Title, Text } from '@we-ui-components/base';
import { Box } from 'grommet';
import { IStores } from 'stores';
import { Form, Input, MobxForm, isRequired } from '@we-ui-components/form';
import { ButtonEx, Spinner2, Panel } from 'ui';
import { inject, observer } from 'mobx-react';
import { IStyledProps } from 'themes';
import { observable } from 'mobx';
import { withTheme } from 'styled-components';

interface ILogin {
  login: string;
  password: string;
}

@inject('user')
@observer
class LoginPageClass extends React.Component<
  IStyledProps & Pick<IStores, 'user'>
> {
  defaultData: ILogin = {
    login: '',
    password: ''
  };

  @observable data: ILogin = this.defaultData;

  @observable isLoading = false;

  formRef: MobxForm;

  @observable error = '';

  componentDidMount() {
    window.addEventListener('keyup', this.enterPressListener);
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.enterPressListener);
  }

  enterPressListener = (e: KeyboardEvent) => {
    if (e.key === 'Enter') this.onSubmit();
  };

  onSubmit = async (event?: Event) => {
    const { user } = this.props;

    if (event) {
      event.preventDefault();
    }

    const { login, password }: ILogin = await this.formRef.validateFields();

    try {
      this.isLoading = true;
      await user.loginEx(login, password);
    } catch (error) {
      this.error = 'Введен неверный логин или пароль';
    } finally {
      this.isLoading = false;
    }
  };

  render() {
    const { theme } = this.props;
    console.log(this.error);
    return (
      <Box
        style={{
          backgroundColor: theme.palette.LightGrey,
          backgroundSize: '100%',
          backgroundRepeat: 'no-repeat'
        }}
        flex={{ grow: 1 }}
        width={{ min: '100vw' }}
      >
        <Box
          align="center"
          justify="center"
          style={{ margin: 'auto', marginTop: '3.5em' }}
          direction="row"
          gap="30px"
        >
          <Panel
            align="stretch"
            width="544px"
            style={{ maxWidth: '100vw' }}
            pad="32px"
          >
            <Box style={{ paddingBottom: 33, width: '100%' }}>
              <Box direction="row" gap="10px" align="baseline">
                <Title style={{ fontSize: '26px' }} color="Black" bold>
                  Добро пожаловать в систему создания голосования
                </Title>
              </Box>
            </Box>
            <Form
              ref={(ref: any): void => (this.formRef = ref)}
              data={this.data}
            >
              <Input placeholder="Логин" name="login" rules={[isRequired]} />
              <Input
                placeholder="Пароль"
                name="password"
                style={{ marginTop: 18 }}
                type="password"
                rules={[isRequired]}
              />
            </Form>

            {this.error && (
              <Box align="center" style={{ paddingTop: '10px' }}>
                <Text size="small" color="Red">
                  {this.error}
                </Text>
              </Box>
            )}

            <ButtonEx
              size="auto"
              style={{
                marginTop: 18,
                padding: 16,
                height: 46,
                alignItems: 'center',
                fontSize: '13px',
                maxWidth: '100%',
                width: '100%'
              }}
              spinnerRender={ButtonSpinner}
              isLoading={this.isLoading}
              onClick={() => {
                this.onSubmit();
              }}
            >
              Войти
            </ButtonEx>
          </Panel>
        </Box>
      </Box>
    );
  }
}

const ButtonSpinner = (
  <Spinner2
    height="16px"
    width="16px"
    color="white"
    style={{ marginRight: 5 }}
  />
);

export const LoginPage = withTheme(LoginPageClass as any);
