import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

export const StyledRouterLink = styled(RouterLink)`
  color: ${props => props.theme.palette.Green};
`;

export function ExternalLink(props: AnchorProps) {
  return (
    <StyledAnchorElement target="_blank" rel="noopener noreferrer" {...props} />
  );
}

export function ExternalLinkBlue(props: AnchorProps) {
  return (
    <StyledAnchorElementBlue target="_blank" rel="noopener noreferrer" {...props} />
  );
}

export const WrapperExternalLink = styled(ExternalLink)`
  text-decoration: none;
  color: inherit;
`;

export const StyledAnchorElement = styled.a`
  color: ${props => props.theme.palette.Green};
`;

export const StyledAnchorElementBlue = styled.a`
  color: ${props => props.theme.palette.Blue600};
`;

export const WrapperRouterLink = styled(RouterLink)`
  text-decoration: none;
  color: inherit;
`;

type AnchorProps = React.ComponentProps<typeof StyledAnchorElement>;
