import * as _ from 'lodash';
import * as moment from 'moment';
import { isEmptyString } from './textUtils';

export function createValidate(
  func: (value: any, data?: any) => boolean,
  error: any
) {
  return {
    validator(
      rule: any[],
      value: any,
      callback: (errors: any[]) => void,
      storeData?: any
    ) {
      const errors = [];

      if (func(value, storeData)) {
        errors.push(error);
      }
      callback(errors);
    }
  };
}

export function isRequired(msg?: string) {
  return {
    ...createValidate(
      v =>
        v === undefined ||
        v === null ||
        isEmptyString(v) ||
        (v instanceof Array && !v.length),
      msg || 'Пожалуйста, заполните поле'
    ),
    validateType: 'requiredValidator'
  };
}

export function isPositive(msg?: string) {
  return {
    ...createValidate(
      v => Number(v) <= 0,
      msg || 'Значение должно быть больше 0'
    ),
    validateType: 'requiredValidator'
  };
}

export function lessThan(value: number, msg?: string) {
  return {
    ...createValidate((v, data) => {
      return Number(v) > value;
    }, msg || `Значение не должно превышать ${value}`),
    validateType: 'requiredValidator'
  };
}

const webSitePattern = /^((https?|s?ftp):\/\/)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

export function isCorrectUrl(msg?: string) {
  return {
    ...createValidate(v => {
      return !webSitePattern.test(v);
    }, msg || 'Ссылка невалидна'),
    validateType: 'requiredValidator'
  };
}

export function allowEmpty(validatorToAllow: { validator: Function }) {
  return {
    validator(
      rule: any[],
      value: any,
      callback: (errors: any[]) => void,
      storeData?: any
    ) {
      if (value.length === 0) {
        callback([]);
      } else {
        validatorToAllow.validator(rule, value, callback, storeData);
      }
    }
  };
}

export function conditiallyRequired(condition: () => boolean, msg?: string) {
  return {
    validator(
      rule: any[],
      value: any,
      callback: (errors: any[]) => void,
      storeData?: any
    ) {
      if (condition()) {
        isRequired(msg).validator(rule, value, callback, storeData);
      } else {
        callback([]);
      }
    }
  };
}

interface ValidatorFunc<T = string> {
  (rule: any[], value: T, callback: (errors: any[]) => void): void;
}

export const maxLength = (length: number, msg?: string): ValidatorFunc => (
  _,
  value,
  callback
) => {
  const errors = [];

  if (value.length > length) {
    const defaultMsg = `Превышена допустимая длина текста (${length} символов)`;
    errors.push(msg || defaultMsg);
  }

  callback(errors);
};

export const isTheSameAs = (fieldName: string, err: string) => {
  return {
    ...createValidate(
      (value, formData) => _.get(formData, fieldName) !== value,
      err
    ),
    validateType: 'requiredValidator'
  };
};

export const nonEmptyFilesRule = (
  _: any,
  value: File[],
  cb: (errors: any[]) => void
) => {
  const errors = [];

  if (!value || value.length === 0) {
    errors.push('Прикрепите документы');
  }

  cb(errors);
};

export const isLengthBetween = (minLength: number, maxLength: number) => {
  return {
    validator(
      rule: any[],
      value: string,
      callback: (errors: any[]) => void,
      storeData?: any
    ) {
      const errors = [];

      if (!value || value.length < minLength || value.length > maxLength) {
        let error = `Длина значения должна быть от ${minLength} до ${maxLength} [${value.length}]`;
        if (minLength === maxLength) {
          error = `Длина значения должна быть ${minLength} [${value.length}]`;
        }
        errors.push(error);
      }

      callback(errors);
    },
    validateType: 'requiredValidator'
  };
};

export const limitLength = (value: string | number, limit = 19) =>
  String(value).slice(0, limit);

export const oneOfLengths = (
  lengths: number[],
  message = `Длина значения может быть ${lengths.join(' или ')} символов`
) => createValidate(value => !lengths.includes(value.length), message);

export const hasWords = (count: number, message: string) =>
  createValidate((value: string) => {
    if (value) {
      const names = value.split(' ').filter(item => item !== '');
      if (names.length >= count) {
        return false;
      }
    }
    return true;
  }, message);

export const moreThanZero = {
  validator(
    rule: any[],
    value: any,
    callback: (errors: any[]) => void,
    storeData?: any
  ) {
    const errors = [];

    if (!value || Number(value) <= 0) {
      errors.push('Значение должно быть больше 0');
    }

    callback(errors);
  },
  validateType: 'requiredValidator'
};

export const isCorrectLastName = createValidate(
  value => !/^[а-я]+(-[а-я]+)*$/i.test(value),
  'Пожалуйста, используйте символы кириллицы'
);

export function isCorrectTime(msg?: string) {
  return {
    ...createValidate(v => {
      const [hours, minutes] = v.split(':');

      return !v || Number(hours) > 23 || Number(minutes) > 59;
    }, msg || 'Некорректный формат'),
    validateType: 'requiredValidator'
  };
}

export function notEqualDataField(fieldName: string, msg?: string) {
  return {
    ...createValidate((v, formData) => {
      return v.valueOf() === formData[fieldName].valueOf();
    }, msg || `Значение не должно совпадать с полем ${fieldName}`),
    validateType: 'requiredValidator'
  };
}

export function isPresentTime(msg?: string) {
  return {
    ...createValidate(v => {
      return moment(v).valueOf() < moment().valueOf();
    }, msg || `Укажите будущую дату и время`),
    validateType: 'requiredValidator'
  };
}

export function isAfterDateField(
  fieldName: string,
  diff: number,
  msg?: string
) {
  return {
    ...createValidate((v, formData) => {
      return moment(v).valueOf() < moment(formData[fieldName]).valueOf() + diff;
    }, msg || `Эта дата должна быть не раньше ${fieldName}`),
    validateType: 'requiredValidator'
  };
}

export const limitFileSize = (
  size: number,
  message = 'Размер прикрепленного файла превышает допустимое значение'
) => {
  return createValidate((value: File[]) => {
    return value.some(f => f.size > size);
  }, message);
};
