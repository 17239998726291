// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3d0cX7n2XOedUxv5PO_GsH{min-width:960px;height:100%}._1SNXhvJZzzMDs3mDpkwrxI{background:linear-gradient(to top,#dbf1ff,#fff)}div.notification-warning{background:Yellow600;border-left:8px solid rgba(206,156,9,0.639)}div.notification-warning p.notification-message,div.notification-warning p.notification-title{color:Basic1000}div.notification-warning p.notification-message{font-size:fontSizeDefault}div.notification-warning p.notification-title{font-size:fontSizeBold}.react-datepicker-popper{z-index:16 !important}", "",{"version":3,"sources":["webpack://./src/app/App.styl"],"names":[],"mappings":"AAAA,yBAAS,eAAe,CAAC,WAAW,CAAC,yBAAmB,+CAA+C,CAAC,yBAAiC,oBAAoB,CAAC,2CAA2C,CAAC,8FAA8G,eAAe,CAAC,gDAAwD,yBAAyB,CAAC,8CAAsD,sBAAsB,CAAC,yBAAiC,qBAAqB","sourcesContent":[".content{min-width:960px;height:100%}.appContainerLogin{background:linear-gradient(to top,#dbf1ff,#fff)}:global div.notification-warning{background:Yellow600;border-left:8px solid rgba(206,156,9,0.639)}:global div.notification-warning p.notification-message,:global div.notification-warning p.notification-title{color:Basic1000}:global div.notification-warning p.notification-message{font-size:fontSizeDefault}:global div.notification-warning p.notification-title{font-size:fontSizeBold}:global .react-datepicker-popper{z-index:16 !important}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "_3d0cX7n2XOedUxv5PO_GsH",
	"appContainerLogin": "_1SNXhvJZzzMDs3mDpkwrxI"
};
export default ___CSS_LOADER_EXPORT___;
