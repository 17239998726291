import { css } from 'styled-components';

const propertiesNames = {
  coverHeight: '--cover-height',
  coverWidth: '--cover-width',
  previewHeight: '--preview-height',
  previewWidth: '--preview-width',
  gapHorizontal: '--gap-horizontal',
  gapVertical: '--gap-vertical'
};

const galleryTheme = createThemeHelper();

const GalleryCssTheme = css`
  ${propertiesNames.coverHeight}: 200px;
  ${propertiesNames.coverWidth}: 150px;
  ${propertiesNames.previewHeight}: 95px;
  ${propertiesNames.previewWidth}: var(--preview-height);
  ${propertiesNames.gapHorizontal}: 16px;
  ${propertiesNames.gapVertical}: 10px;
`;

export { GalleryCssTheme, galleryTheme };

function createThemeHelper() {
  const nameEntries = Object.entries(propertiesNames);
  const result = {} as typeof propertiesNames;

  for (const [name, value] of nameEntries) {
    result[name] = `var(${value})`;
  }

  return result;
}
