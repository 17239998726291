import {
  OptionApiDto,
  OptionCreateRequest,
  Page
} from 'interfaces/apiEntities';
import { createQS } from 'we-oauth2/lib/auth/api-utils';
import { api } from './api';
import { ENDPOINTS } from './endpoints';

async function getOptions(
  pollId: string,
  questionNum: number,
  params?: Record<string, any>
): Promise<Page<OptionApiDto>> {
  const response = await api.get<Page<OptionApiDto>>(
    ENDPOINTS.options(pollId, questionNum),
    params
  );

  for (const option of response.content) {
    option.files = convertOptionFilesIdsToURLs({
      pollId,
      questionNum,
      optionNum: option.num,
      files: option.files
    });
  }

  return response;
}

function createOption(
  pollId: string,
  questionNum: number,
  optionData: OptionCreateRequest
): Promise<OptionApiDto> {
  return api.post<OptionApiDto>(
    ENDPOINTS.options(pollId, questionNum),
    optionData
  );
}

function editOption(
  pollId: string,
  questionNum: number,
  optionNum: number,
  optionData: OptionCreateRequest
) {
  return api.patch<OptionApiDto>(
    ENDPOINTS.optionByNum(pollId, questionNum, optionNum),
    optionData
  );
}

function deleteOption(pollId: string, questionNum: number, optionNum: number) {
  return api.del(ENDPOINTS.optionByNum(pollId, questionNum, optionNum));
}

type convertFilesParams = {
  pollId: string;
  questionNum: number;
  optionNum: number;
  files: OptionApiDto['files'];
};

function convertOptionFilesIdsToURLs({
  pollId,
  questionNum,
  optionNum,
  files
}: convertFilesParams) {
  console.log(api.getToken(), files);
  const baseUrl =
    ENDPOINTS.optionFile(pollId, questionNum, optionNum) +
    createQS({ access_token: api.getToken() });
  return files.map(id => `${baseUrl}/${id}`);
}

export {
  getOptions,
  createOption,
  editOption,
  deleteOption,
  convertOptionFilesIdsToURLs
};
