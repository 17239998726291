import { Title, Text } from '@we-ui-components/base';
import { BackButton } from 'components/BackButton';
import { PollStatusBadge } from 'components/PollStatusBadge';
import { Routes } from 'constants/routes';
import { Box } from 'grommet';
import { POLL_STATUS } from 'interfaces/apiEntities';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useParams } from 'react-router';
import { useStores } from 'stores';
import { StyledButton } from 'ui/StyledButton';
import { formatZeroDecimals } from 'utils/formatNumber';
import { AboutPoll } from './components/AboutPoll';
import { GeneratedCodeModal } from './components/GeneratedCodeModal';
import { PollQuestions } from './components/questions/Questions';

export const PollDetailsPage = observer(function PollDetailsPage() {
  const { pollId } = useParams<{ pollId: string }>();
  const { activePoll, actionModals } = useStores();

  const handleGenerateCodeClick = () => {
    actionModals.open(() => <GeneratedCodeModal pollId={pollId} />, {
      applyText: () => <span>Закрыть</span>,
      onApply: async () => {},
      title: 'Идентификатор голосования успешно сгенерирован',
      noValidation: true
    });
  };

  const { poll } = activePoll;

  const votesVisible = () =>
    poll.status === POLL_STATUS.IN_PROGRESS ||
    poll.status === POLL_STATUS.FINISHED;

  React.useEffect(() => {
    activePoll.getPollById(pollId);
  }, [pollId]);

  if(!poll) {
    return null;
  }

  return (
    <Box pad={{ vertical: '2.5em', horizontal: '4em' }} width="100%">
      <Box direction="row" justify="between">
        <BackButton url={`/${Routes.polls}`} />
        <StyledButton onClick={handleGenerateCodeClick} variant="secondary">
          Сгенерировать код
        </StyledButton>
      </Box>

      {activePoll.fetchPollStatus === 'success' ? (
        <Box>
          <Box margin={{ top: '1em' }} direction="row" gap="1em" align="start">
            <Title size="27px" fontWeight="700">
              {poll.name}
            </Title>
            <PollStatusBadge status={poll.status} />
          </Box>

          {votesVisible() ? (
            <Box margin={{ top: '0.5em' }} direction="row" align="center">
              <Text size="small">Голосов:</Text>
              <img
                style={{ marginLeft: '0.5em', marginRight: '0.2em' }}
                width="13px"
                height="8px"
                src="/icons/all-done-icon.svg"
              />

              <Text size="small">{formatZeroDecimals(poll.voteCount)}</Text>
            </Box>
          ) : null}

          <Box direction="row" align="start" margin={{ top: '2em' }} gap="4em">
            <PollQuestions flex={{ grow: 1 }} margin={{ right: 'auto' }} />
            <AboutPoll width="350px" />
          </Box>
        </Box>
      ) : null}
    </Box>
  );
});
