import { Box } from 'grommet';
import styled from 'styled-components';

export const ActionsContainer = styled(Box)`
  display: grid;
  max-width: 450px;
  gap: 0.6em;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

  margin-top: 1em;
`;
