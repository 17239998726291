import { TToken } from 'we-oauth2/lib/auth/interfaces';
import { TRole } from './apiTypes';

export interface ITokenEx extends TToken {
  accountId: string;
  personInfo: IUserInfo;
}

export interface IUserMeta {
  position?: string;
  patronymic?: string;
  permitionDocumentName?: string;
  phone?: UserAuthenticationFactor;
  email?: UserAuthenticationFactor;
  company: string;
  district: string;
}

interface UserAuthenticationFactor<T = string> {
  value: T;
  confirmed: boolean;
}

export interface IUserInfo {
  accountId: string;
  businessRoles: Array<TRole>;
  companyId: string;
  companyName: string;
  displayName: string;
  email: string | null;
  firstName: string;
  lastName: string;
  nodeAlias: string;
  participantAddress: string | null;
  participantPublicKey: string | null;
  personId: string;
  phone: string;
  meta: IUserMeta | null;
}

export interface IAccount {
  accountId: string;
  accountNonExpired: boolean;
  accountNonLocked: boolean;
  credentialsNonExpired: boolean;
  enabled: boolean;
  password: string | null;
  roles: Array<string>;
  username: string;
}

export interface ICompanyMeta {
  bankName: string;
  logo?: string;
  paymentAccount: string;
  directorLastName: string;
  directorFirstName: string;
  directorPatronymic: string;
  correspondentAccount: string;
  bankIdentificationCode: string;

  bik: string;
  address?: string;
  directorFullName?: {
    lastName: string;
    firstName: string;
    middleName: string;
  };
}

export interface ICompany {
  id: string;
  nodeAlias: string;
  name: string;
  shortName: string;
  type: TRole;
  address: string;
  phone: string;
  email: string;
  okpo?: string;
  ogrn: string;
  inn: string;
  kpp: string;
  meta: ICompanyMeta | null;
}

export interface IBusinessRole {
  name: TRole;
  displayName: string;
  description: string;
}

export interface ICompanyType extends IBusinessRole {}

export enum IP_OBJECT_STATUS {
  ALL = 'ALL', //Only UI
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED'
}

export interface IpObjectList {
  id: string | null;
  name: string;
  ownerId: string;
  status: IP_OBJECT_STATUS;
  validTillDate: string;
  logo: string;
  companyName: string;
  companyShortName: string;
}

export interface IpObject extends IpObjectList {
  legalDocumentNumber: string;
  validFromDate: string;
  validTillDate: string;
}

export interface RegistrationRequest {
  name: string;
  surname: string;
  patronymic: string;
  email: string;
  phoneNumber: string;
  encodedPassword: string;
}

export interface RegistrationResponse {
  accountId: string;
  personId: string;
}

export interface ConfirmationRequest {
  accountId: string;
  confirmationCode: string;
}

interface CommonViolation {
  inn: string;
  name: string;
  environment: 'INTERNET' | 'PHYSICAL';
  date: Date | string;
  comment: string;
  reason?: string;
  files: Array<{ name: string; content: string; type: string }>;
}

export interface InternetViolation extends CommonViolation {
  url: string;
  type: string;
}

export interface PhysicalViolation extends CommonViolation {
  address: string;
  marketName: string;
  floor: string;
  pavilion: string;
  addressComment: string;
}

export interface ListViolationResponse {
  id: string;
  date: string;
  created: string;
  ipObject: {
    id: string;
    created: string;
    companyId: string;
    companyName: string;
    companyShortName: string;
    logo: string;
    name: string;
    legalDocumentNumber: string;
    validFromDate: string;
    validTillDate: string;
  };
  environment: 'INTERNET' | 'PHYSICAL';
  name: string;
  status: ViolationStatus;
  type: ViolationType;
  lastUnsuccessfulTxId?: string;
  lastUnsuccessfulTxStatus?: string;
}

export interface Page<T> {
  content: T[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export enum ViolationStatus {
  NEW = 'NEW',
  REJECTED = 'REJECTED',
  DUPLICATE = 'DUPLICATE',
  IN_WORK = 'IN_WORK',
  DECLINED = 'DECLINED',
  CONFIRMED = 'CONFIRMED'
}

export enum ViolationType {
  COMMERCIAL_USE = 'COMMERCIAL_USE'
}

interface CommonViolationResponse {
  comment?: string;
  date: string;
  created: string;
  files: FileParams[];
  id: string;
  inn: string;
  ipObject: {
    companyId: string;
    companyName: string;
    companyShortName: string;
    id: string;
    legalDocumentNumber: string;
    logo: string;
    name: string;
    status: 'ACTIVE' | 'DEACTIVATED';
    validFromDate: string;
    validTillDate: string;
  };
  name: string;
  status: ViolationStatus;
  type: ViolationType;
  reason?: string;
  environment: 'INTERNET' | 'PHYSICAL';
  lastUnsuccessfulTxId?: string;
  lastUnsuccessfulTxStatus?: string;
}

export interface PhysicalViolationResponse extends CommonViolationResponse {
  environment: 'PHYSICAL';
  address: string;
  marketName: string;
  floor: string;
  pavilion: string;
  addressComment: string;
}

export interface InternetViolationResponse extends CommonViolationResponse {
  environment: 'INTERNET';
  url: string;
}

export type ViolationResponse =
  | PhysicalViolationResponse
  | InternetViolationResponse;

export interface FileParams {
  externalRef: string;
  fileHash: string;
  name: string;
}

interface CommonViolationDuplicateResponse {
  id: string;
  name: string;
  type: ViolationType;
  date: string;
  comment?: string;
  files: FileParams[];
  environment: 'INTERNET' | 'PHYSICAL';
  created: string;
}

export interface ViolationDuplicateResponse
  extends CommonViolationDuplicateResponse {
  address?: string;
  marketName?: string;
  floor?: string;
  pavilion?: string;
  addressComment?: string;
  url?: string;
  status: ViolationStatus;
}

//test
export enum PROJECT_STATUS {
  ALL = 'ALL',
  IN_REVIEW = 'IN_REVIEW',
  REWORK_REQUIRED = 'REWORK_REQUIRED',
  REWORK_COMPLETED = 'REWORK_COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  DECLINED = 'DECLINED',
  CANCELED = 'CANCELED',
  DRAFT = 'DRAFT',
  APPROVED = 'APPROVED',
  DONE = 'DONE',
  ARCHIVED = 'ARCHIVED'
}

export interface ISchool {
  districtId: number;
  districtName: string;
  id: string;
  name: string;
  personCount: number;
  pollId: string;
  schoolGroupId: number;
  schoolGroupName: number;
}

export interface IFile {
  id: string;
  name: string;
  type: string;
}

export type DistrictType = 'OBLAST_DISTRICTS' | 'VOLGOGRAD_DISTRICTS';

export type IPollResults = {
  votesCountByProjectId: Record<string, number>;
};

export interface IUserHasVote {
  createdDate: string;
  encryptedBulletin: string;
  votingTxId: string;
  votingTxStatus: string;
}

export interface INewSystemUser {
  login: string;
  surname: string;
  name: string;
  patronymic?: string;
  email: string;
  password: string;
  roles: TRole[];
  organization: string; // update after backend impl
  district: string; // update after backend impl
  phone: string; // update after backend impl
}

interface IBasicUser {
  businessRole: TRole;
  company: string;
  districtId: string;
  email: string;
  firstName: string;
  lastName: string;
  patronymic: string;
  phone: string;
}

export interface INewUser extends IBasicUser {
  password: string;
}

export interface IUser extends IBasicUser {
  personId: string;
  district: string;
  id: string;
}

export type UpdateUserFullyRequest = Pick<
  IUser,
  | 'email'
  | 'phone'
  | 'firstName'
  | 'lastName'
  | 'patronymic'
  | 'company'
  | 'districtId'
>;

export interface ISystemUser extends Omit<INewSystemUser, 'password'> {
  status: any; // TODO add correct typing
  personId: string;
  /** @deprecated */
  id: string;
}

export type UpdateAccountRequest = {
  login: string;
  password: string;
};

export type UpdatePersonRequest = {
  firstName: string;
  lastName: string;
  patronymic: string;
  roles?: TRole[];
  email: string;
  phone: string;
};

export interface PollApiDto {
  alias: string;
  id: string;
  status: POLL_STATUS;
  name: string;
  votingEndDate: string;
  votingStartDate: string;
  voteCount: number;

  description?: string;
  meta?: Record<string, any>;
  systemStatus?: string;
}

export interface PollCreateRequest {
  // alias: string;
  name: string;
  votingStartDate: string;
  votingEndDate: string;

  description?: string;
  meta?: Record<string, any>;
}

export enum POLL_STATUS {
  NEW = 'NEW',
  PUBLISHED = 'PUBLISHED',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
  ERROR = 'ERROR'
}

export interface QuestionApiDto {
  description: string;
  name: string;
  num: number;
  order: number;
  pollId: string;
  options?: OptionApiDto[];

  meta?: Record<string, any>;
}

export interface QuestionCreateRequest {
  name: string;
  description: string;
  meta?: Record<string, any>;
}

export interface OptionApiDto {
  description: string;
  files: string[];
  name: string;
  num: number;
  order: number;

  meta?: Record<string, any>;
}

export interface OptionCreateRequest {
  name: string;
  description: string;
  files?: File[];
  meta?: Record<string, any>;
}
