export const Routes = {
  login: 'login',
  polls: 'polls',
  createPoll: 'polls/create',

  // old
  landing: 'land',

  loginOrganizer: 'login-organizer',
  loginApplicant: 'login-applicant',
  loginVoter: 'login-voter',

  homeOrganizer: 'organizer',
  homeApplicant: 'applicant',
  homeVoter: 'voter',

  projects: 'projects',
  archive: 'archive',

  poll: 'poll',

  questionAnswer: 'question-answer',
  infoAboutProgram: 'info-program',

  createVote: 'create-vote',
  createProject: 'create-project',

  profile: 'profile',

  projectPage: 'project',

  users: 'users'
};
