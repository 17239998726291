import * as React from 'react';
import { Box } from 'grommet';
import { Routes } from 'constants/routes';
import {
  PollApiDto,
  PollCreateRequest,
  POLL_STATUS
} from 'interfaces/apiEntities';
import { PollStatusBadge } from 'components/PollStatusBadge';
import { Text } from '@we-ui-components/base';
import { isDefined } from 'utils/formatDates';
import { formatZeroDecimals } from 'utils/formatNumber';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { TransparentButton, Card, DateRange } from 'ui';
import { useStores } from 'stores';
import { EditPollForm } from 'modalPages/EditPollForm';

type PollListItemProps = {
  poll: PollApiDto;
};

export function PollListItem({ poll }: PollListItemProps) {
  const { actionModals, polls } = useStores();

  const handleEditClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault(); // <-- suppress redirect

    actionModals.open(EditPollForm, {
      title: 'Редактирование голосования',
      applyText: () => <span>Сохранить</span>,
      closeText: () => <span>Отменить</span>,
      async onApply(data: PollCreateRequest) {
        return polls.editPoll(poll.id, data);
      },
      initData: poll,
      noValidation: true
    });
  };

  const isEditable = () => poll.status === POLL_STATUS.NEW;

  return (
    <InvisibleLink to={`/${Routes.polls}/${poll.id}`}>
      <StyledCard>
        {isEditable() ? (
          <EditButtonWrapper onClick={handleEditClick}>
            <img src="/icons/edit-icon.svg" />
          </EditButtonWrapper>
        ) : null}

        <PollStatusBadge status={poll.status} />

        <Text
          margin={{ top: '10px' }}
          style={{ flex: '1 0 auto' }}
          fontWeight="700"
        >
          {poll.name}
        </Text>

        <DateRange
          margin={{ top: '12px' }}
          startDate={poll.votingStartDate}
          endDate={poll.votingEndDate}
        />

        <Box
          direction="row"
          width="100%"
          margin={{ top: '14px' }}
          justify="end"
        >
          {isDefined(poll.voteCount) ? (
            <Box direction="row" gap="0.2em" align="center">
              <img width="13px" height="8px" src="/icons/all-done-icon.svg" />
              <Text size="xsmall">{formatZeroDecimals(poll.voteCount)}</Text>
            </Box>
          ) : null}
        </Box>
      </StyledCard>
    </InvisibleLink>
  );
}

const StyledCard = styled(Card)`
  height: 100%;
  width: 100%;

  position: relative;
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  padding: 16px 20px 20px;
`;

const InvisibleLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const EditButtonWrapper = styled(TransparentButton)`
  position: absolute;
  top: 14px;
  right: 14px;

  padding: 5px;
`;
