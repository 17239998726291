import { UserInfo } from 'models';
import { api, ENDPOINTS } from 'services';
import { IUserInfo } from 'interfaces';
import { createStoresContext } from 'we-data-layer/lib/connect';
import RouterStore from 'stores/RouterStore';
import { UserStoreEx } from 'stores/UserStore';
import { CatalogsStore } from './CatalogsStore';
import { ModalsStore } from './ModalsStore';
import { ActionModalsStore } from './ActionModalsStore';

import { ActivePollStore } from './ActivePollStore';
import { PollListStore } from './PollListStore';

export type onAuthSuccessCb = (userInfo: UserInfo) => Promise<void>;

const createUserStore = (onAuthSuccess: onAuthSuccessCb): UserStoreEx => {
  const getUserInfo = () =>
    api
      .get(ENDPOINTS.userInfo())
      .then((userInfo: IUserInfo) => UserInfo.New(userInfo));

  const userStore = new UserStoreEx({
    userService: { login: api.login, logout: api.logout, getUserInfo },
    onAuthSuccess: (iUserInfo: IUserInfo) => {
      return new Promise(resolve => {
        resolve(UserInfo.New(iUserInfo));
      }).then(onAuthSuccess);
    }
  });

  userStore.init();

  return userStore;
};

export interface IStores {
  //usual
  user?: UserStoreEx;
  routing?: RouterStore;
  catalogs?: CatalogsStore;
  modals?: ModalsStore;
  actionModals?: ActionModalsStore;
  //business
  polls?: PollListStore;
  activePoll?: ActivePollStore;
}

let onAuthSuccess;
const user = createUserStore(onAuthSuccess);

const stores: IStores = {};

stores.user = user;
user.stores = stores;
onAuthSuccess = user.onAuthSuccess;

stores.routing = new RouterStore();
stores.catalogs = new CatalogsStore(stores);
stores.modals = new ModalsStore();
stores.actionModals = new ActionModalsStore();

stores.activePoll = new ActivePollStore(stores);
stores.polls = new PollListStore(stores);

if (!process.env.production) {
  window.stores = stores;
}

const { StoresProvider, useStores } = createStoresContext<typeof stores>();
export { StoresProvider, useStores };

export default stores;
