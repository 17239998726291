import { Box, BoxProps } from 'grommet';
import { POLL_STATUS } from 'interfaces/apiEntities';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useStores } from 'stores';
import { StyledButton } from 'ui';
import { QuestionForm, FormData as QuestionFormData } from './QuestionForm';
import { QuestionListItem } from './QuestionListItem';

export const PollQuestions = observer<BoxProps>(function PollQuestions(props) {
  const { activePoll, actionModals } = useStores();

  const isError = activePoll.questionListStore.fetchStatus === 'error';
  const isSuccess = activePoll.questionListStore.fetchStatus === 'success';
  const isLoading = !(isError || isSuccess);

  const hasAtLeast1Question = activePoll.questions.length !== 0;

  return (
    <Box flex={{ grow: 1 }} align="start" width={{ max: '830px' }} {...props}>
      {isLoading ? <span>Загрузка...</span> : null}
      {isError ? <span>Error</span> : null}
      {isSuccess ? (
        <>
          {activePoll.questions.map(q => (
            <QuestionListItem question={q} key={q.num} />
          ))}

          {activePoll.isEditable && !hasAtLeast1Question ? (
            <StyledButton
              style={{
                padding: '14px 20px'
              }}
              variant="primary"
              onClick={() => {
                actionModals.open(QuestionForm, {
                  title: 'Добавление вопроса',
                  applyText: () => <span>Создать</span>,
                  closeText: () => <span>Отменить</span>,
                  onApply: (data: QuestionFormData) =>
                    activePoll.createQuestion(data),
                  noValidation: true
                });
              }}
            >
              <img style={{ marginRight: '0.5em' }} src="/icons/add-icon.svg" />
              Добавить вопрос
            </StyledButton>
          ) : null}
        </>
      ) : null}
    </Box>
  );
});
