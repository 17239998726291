import {
  OptionApiDto,
  OptionCreateRequest,
  PollApiDto,
  PollCreateRequest,
  POLL_STATUS,
  QuestionApiDto,
  QuestionCreateRequest
} from 'interfaces/apiEntities';
import { action, computed, observable } from 'mobx';
import { NormalizedError } from 'services/api/errorHandler';
import { statusFetching } from 'we-oauth2/lib/constants/types';
import * as pollsService from 'services/polls';
import * as questionsService from 'services/questions';
import * as optionsService from 'services/options';
import { uploadFile } from 'services/polls';
import { IStores } from '.';
import { ListStoreConstructor } from './core/ListStoreConstructor';

export async function uploadFiles(files: File[]): Promise<string[]> {
  if (!files.length) {
    return [];
  }
  const loadedFiles = await Promise.all(files.map(file => uploadFile(file)));
  return loadedFiles.map(i => i.id);
}

export class ActivePollStore {
  @observable fetchPollStatus: statusFetching = 'init';
  @observable fetchError: NormalizedError;

  @observable editPollStatus: statusFetching = 'init';
  @observable editPollError: NormalizedError;

  @observable poll: PollApiDto;
  questionListStore: QuestionsListStore;

  @computed get isEditable() {
    return this.poll.status === POLL_STATUS.NEW;
  }

  @computed get questions() {
    return this.questionListStore?.data || [];
  }

  @action.bound
  public async editPoll(pollData: PollCreateRequest) {
    this.editPollError = null;
    this.editPollStatus = 'fetching';

    try {
      this.poll = await pollsService.editPoll(this.poll.id, pollData);

      this.editPollStatus = 'success';
    } catch (error) {
      this.editPollError = error;
      this.editPollStatus = 'error';
    }
  }

  @action.bound
  public async publishPoll() {
    this.poll = await pollsService.publishPoll(this.poll.id);
  }

  public async editOption(
    questionNum: number,
    optionNum: number,
    optionData: OptionCreateRequest
  ) {
    const hasIds = optionData.files.some(i => typeof i === 'string');

    const fileIds = hasIds
      ? optionData.files
      : await uploadFiles(optionData.files);
    const optionWithImagesId = {
      ...optionData,
      files: fileIds
    };

    return this.questionListStore.editOption(
      this.poll.id,
      questionNum,
      optionNum,
      //@ts-ignore
      optionWithImagesId
    );
  }

  public async createQuestion(question: QuestionCreateRequest) {
    return this.questionListStore.createQuestion(this.poll.id, question);
  }

  public async createOption(questionNum: number, option: OptionCreateRequest) {
    console.log('create option', option);
    try {
      const fileIds = await uploadFiles(option.files);
      const optionWithImagesId = {
        ...option,
        files: fileIds
      };

      return this.questionListStore.createOption(
        this.poll.id,
        questionNum,
        //@ts-ignore
        optionWithImagesId
      );
    } catch (err) {
      console.warn(err);
      return Promise.reject(err);
    }
  }
  public async deleteOption(questionNum: number, optionNum: number) {
    return this.questionListStore.deleteOption(
      this.poll.id,
      questionNum,
      optionNum
    );
  }

  public async editQuestion(
    questionNum: number,
    questionData: QuestionCreateRequest
  ) {
    return this.questionListStore.editQuestion(
      this.poll.id,
      questionNum,
      questionData
    );
  }

  public async deleteQuestion(questionNum: number) {
    return this.questionListStore.deleteQuestion(this.poll.id, questionNum);
  }

  // TODO: remove after add correct api for options update for question
  public fetchQuestionsLight() {
    setTimeout(() => {
      return this.questionListStore.fetchQuestionsWithOptions();
    }, 100);
  }

  public async fetchQuestions() {
    this.questionListStore?.clear();
    this.questionListStore = new QuestionsListStore(this.stores, this.poll.id);
    this.questionListStore.fetchQuestionsWithOptions();
  }

  constructor(private stores: IStores) {}

  @action.bound
  async getPollById(pollId: string) {
    this.fetchError = null;
    this.fetchPollStatus = 'fetching';

    try {
      this.poll = await pollsService.getPollById(pollId);

      if (this.poll.id) {
        await this.fetchQuestions();
      }
      this.fetchPollStatus = 'success';
    } catch (error) {
      this.fetchError = error;
      this.fetchPollStatus = 'error';
    }
  }

  public getOptionsListStore(questionNum: number) {
    return this.questionListStore.optionListStores.get(questionNum);
  }
}

class QuestionsListStore extends ListStoreConstructor<QuestionApiDto> {
  @observable optionListStores: Map<number, OptionsListStore> = new Map();

  @observable createStatus: statusFetching = 'init';
  @observable createError: NormalizedError;

  @observable deleteStatus: statusFetching = 'init';
  @observable deleteError: NormalizedError;

  @observable editStatus: statusFetching = 'init';
  @observable editError: NormalizedError;

  public async createQuestion(pollId: string, question: QuestionCreateRequest) {
    this.createStatus = 'fetching';
    this.createError = null;

    try {
      const response = await questionsService.createQuestion(pollId, question);
      this.allData.push(response);

      const store = new OptionsListStore(this.stores, pollId, response.num);
      store.setSuccess();

      this.optionListStores.set(response.num, store);

      this.createStatus = 'success';
    } catch (error) {
      this.createError = error;
      this.createStatus = 'error';
    }
  }

  public async createOption(
    pollId: string,
    questionNum: number,
    optionData: OptionCreateRequest
  ) {
    const store = this.optionListStores.get(questionNum);
    return store.createOption(pollId, questionNum, optionData);
  }

  public async editOption(
    pollId: string,
    questionNum: number,
    optionNum: number,
    optionData: OptionCreateRequest
  ) {
    const store = this.optionListStores.get(questionNum);
    return store.editOption(pollId, questionNum, optionNum, optionData);
  }

  public async deleteOption(
    pollId: string,
    questionNum: number,
    optionNum: number
  ) {
    const store = this.optionListStores.get(questionNum);
    store.deleteOption(pollId, questionNum, optionNum);
  }

  async fetchQuestionsWithOptions() {
    await this.fetch();

    if (this.data.length > 0) {
      this.getOptions();
    }
  }

  async getOptions() {
    for (const question of this.data) {
      const optionsStore = new OptionsListStore(
        this.stores,
        this.pollId,
        question.num
      );

      this.optionListStores.set(question.num, optionsStore);
      // optionsStore.fetch();
    }
  }

  public async editQuestion(
    pollId: string,
    questionNum: number,
    questionData: QuestionCreateRequest
  ) {
    this.editStatus = 'fetching';
    this.editError = null;

    try {
      const result = await questionsService.editQuestion(
        pollId,
        questionNum,
        questionData
      );
      const questionIndex = this.allData.findIndex(
        it => it.num === questionNum
      );
      this.allData[questionIndex] = result;

      this.editStatus = 'success';
    } catch (error) {
      this.editError = error;
      this.editStatus = 'error';
      // throw new Error(error.detail);
      return Promise.reject(error);
    }
  }

  public async deleteQuestion(pollId: string, questionNum: number) {
    this.deleteStatus = 'fetching';
    this.deleteError = null;

    try {
      await questionsService.deleteQuestion(pollId, questionNum);
      this.deleteStatus = 'success';
      this.allData = this.allData.filter(q => q.num !== questionNum);
    } catch (error) {
      this.deleteError = error;
      this.deleteStatus = 'error';
    }
  }

  constructor(stores: IStores, private pollId: string) {
    super(
      stores,
      async (params: Record<string, any>) =>
        questionsService.getQuestions(pollId, params),
      {}
    );
  }

  public fetchOptionsForQuestion(questionNum: number) {
    const store = new OptionsListStore(this.stores, this.pollId, questionNum);
    this.optionListStores.set(questionNum, store);
    store.fetch();
  }
}

class OptionsListStore extends ListStoreConstructor<OptionApiDto> {
  @observable createStatus: statusFetching = 'init';
  @observable createError: NormalizedError;

  @observable deleteStatus: statusFetching = 'init';
  @observable deleteError: NormalizedError;

  @observable editStatus: statusFetching = 'init';
  @observable editError: NormalizedError;

  public setSuccess() {
    this.fetcher.status = 'success';
  }

  constructor(
    stores: IStores,
    private pollId: string,
    private questionNum: number
  ) {
    super(
      stores,
      async (params: Record<string, any>) =>
        optionsService.getOptions(pollId, questionNum, params),
      {}
    );
  }

  public async createOption(
    pollId: string,
    questionNum: number,
    optionData: OptionCreateRequest
  ) {
    this.createStatus = 'fetching';
    this.createError = null;

    try {
      const response = await optionsService.createOption(
        pollId,
        questionNum,
        optionData
      );
      this.createStatus = 'success';
      this.data.push(response);
    } catch (error) {
      this.createError = error;
      this.createStatus = 'error';
      return Promise.reject(error);
    }
  }

  public async editOption(
    pollId: string,
    questionNum: number,
    optionNum: number,
    optionData: OptionCreateRequest
  ) {
    this.editStatus = 'fetching';
    this.editError = null;
    try {
      const response = await optionsService.editOption(
        pollId,
        questionNum,
        optionNum,
        optionData
      );
      this.editStatus = 'success';
      this.data.push(response);
    } catch (error) {
      this.editError = error;
      this.editStatus = 'error';
      return Promise.reject(error);
    }
  }

  public async deleteOption(
    pollId: string,
    questionNum: number,
    optionNum: number
  ) {
    this.deleteStatus = 'fetching';
    this.deleteError = null;

    try {
      await optionsService.deleteOption(pollId, questionNum, optionNum);
      this.deleteStatus = 'success';
      this.allData = this.allData.filter(it => it.num !== optionNum);
    } catch (error) {
      this.deleteError = error;
      this.deleteStatus = 'error';
    }
  }
}
