import { Title } from '@we-ui-components/base';
import { Routes } from 'constants/routes';
import { Box } from 'grommet';
import * as React from 'react';
import { useHistory } from 'react-router';
import { StyledButton } from 'ui';
import { PollsGrid } from './components/grid';

export function PollsPage() {
  const history = useHistory();

  return (
    <Box
      gap="xlarge"
      pad={{ vertical: '2.5em', horizontal: '4em' }}
      width="100%"
    >
      <Box direction="row" gap="large" align="center">
        <Title fontWeight="700">Голосования</Title>
        <StyledButton
          variant="primary"
          style={{
            padding: '9px 20px'
          }}
          role="link"
          onClick={() => history.push(Routes.createPoll)}
        >
          <img style={{ marginRight: '1ch' }} src="/icons/add-icon.svg" />
          Создать голосование
        </StyledButton>
      </Box>

      <Box flex={{ grow: 1 }}>
        <PollsGrid />
      </Box>
    </Box>
  );
}
