import { Title, Text, Button } from '@we-ui-components/base';
import { Box } from 'grommet';
import { POLL_STATUS, QuestionApiDto } from 'interfaces/apiEntities';
import * as React from 'react';
import { useStores } from 'stores';
import { QuestionForm, FormData as QuestionFormData } from './QuestionForm';
import styled from 'styled-components';
import { TransparentButton, Card, StyledButton } from 'ui';
import { Options } from '../options/Options';
import { observer } from 'mobx-react-lite';

type QuestionListItemProps = {
  question: QuestionApiDto;
};

export const QuestionListItem = observer<QuestionListItemProps>(
  function QuestionListItem({ question }) {
    const { activePoll, actionModals } = useStores();

    const handleEditClick = () => {
      actionModals.open(QuestionForm, {
        title: 'Редактирование вопроса',
        initData: question,
        applyText: () => <span>Создать</span>,
        closeText: () => <span>Отменить</span>,
        onApply: (data: QuestionFormData) =>
          activePoll.editQuestion(question.num, data),
        noValidation: true
      });
    };

    const handleDeleteClick = () => {
      actionModals.open(
        () => (
          <Box direction="row">
            <Text>
              Вы действительно хотите удалить вопрос <b>{question.name}</b> ?
            </Text>
          </Box>
        ),
        {
          title: `Удаление вопроса`,
          applyText: () => <span>Удалить</span>,
          closeText: () => <span>Отмена</span>,
          applyButtonProps: {
            color: 'white',
            bgColor: 'hsl(346, 99%, 53%)'
          },
          noValidation: true,
          onApply: async () => {
            activePoll.deleteQuestion(question.num);
          }
        }
      );
    };

    return (
      <Card
        width={{ min: '100%' }}
        margin={{ bottom: '1em' }}
        style={{ position: 'relative' }}
        pad="32px"
        align="start"
      >
        {activePoll.isEditable ? (
          <ActionsBlock direction="row" gap="0.5em">
            <TransparentButton onClick={handleEditClick}>
              <img src="/icons/edit-icon.svg" />
            </TransparentButton>

            <TransparentButton onClick={handleDeleteClick}>
              <img src="/icons/delete-icon.svg" />
            </TransparentButton>
          </ActionsBlock>
        ) : null}

        <Title size="21px" fontWeight="700">
          {question.name}
        </Title>

        <Text size="14px" margin={{ top: '0.5em' }}>
          {question.description}
        </Text>

        <Options questionNum={question.num} options={question.options} />
      </Card>
    );
  }
);

const ActionsBlock = styled(Box)`
  position: absolute;
  top: 32px;
  right: 32px;

  display: flex;
  flex-direction: row;
`;
