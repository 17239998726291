import { Title } from '@we-ui-components/base';
import { BackButton } from 'components/BackButton';
import { Routes } from 'constants/routes';
import { Box } from 'grommet';
import * as React from 'react';
import { CreatePollForm } from './components/CreatePollForm';

export function CreatePollPage() {
  return (
    <Box pad={{ vertical: '2.5em', horizontal: '4em' }} width="100%">
      <BackButton url={`/${Routes.polls}`} />
      <Title
        margin={{ top: 'small', bottom: 'medium' }}
        size="large"
        fontWeight="700"
      >
        Создание голосования
      </Title>

      <CreatePollForm />
    </Box>
  );
}
