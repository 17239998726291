import * as React from 'react';
import hljs from 'highlight.js/lib/core';
import 'highlight.js/styles/base16/github.css';
import javascript from 'highlight.js/lib/languages/javascript';
import styled from 'styled-components';
import { TransparentButton } from './StyledButton';
import { Box } from 'grommet';

hljs.registerLanguage('javascript', javascript);

interface GeneratedCodeProps {}

const getCode = (props?: any) => {
  return `
  import {pollApi} from 'we-vote-sass/code';

  main();

  async function main() {
    const voter = await promptUser();
    let polls;

    try {
      await pollApi.registerVoter(voter)
      polls = await pollApi.getPolls();
    } catch (error) {
      handleError(error); // up to you
    }
  }
  `;
};

export function GeneratedCode(props: GeneratedCodeProps) {
  const ref = React.useRef();

  React.useLayoutEffect(() => {
    hljs.highlightElement(ref.current);
  }, []);

  const code = getCode();

  const handleCopyClick = () => {
    window.navigator.clipboard.writeText(code);
  };

  return (
    <Box style={{ position: 'relative' }}>
      {copySupported() ? (
        <TransparentButton
          onClick={handleCopyClick}
          style={{
            position: 'absolute',
            top: '1.5em',
            right: '1em'
          }}
        >
          <img height="16px" width="16px" src="/icons/copy-icon.svg" />
        </TransparentButton>
      ) : null}

      <pre
        style={{
          border: '1px solid rgba(15, 98, 254, 0.2)',
          borderRadius: '4px'
        }}
        ref={ref}
      >
        {code}
      </pre>
    </Box>
  );
}

type BoxProps = React.ComponentProps<typeof Box>;

export function Pre({ children, ...props }: BoxProps) {
  const ref = React.useRef<HTMLPreElement>();

  React.useLayoutEffect(() => {
    hljs.highlightElement(ref.current);
  }, []);

  const handleCopyClick = () => {
    const text = ref.current.textContent;
    copyText(text);
  };

  return (
    <PreContainer {...props}>
      {copySupported() ? (
        <TransparentButton
          onClick={handleCopyClick}
          style={{
            position: 'absolute',
            top: '1.5em',
            right: '1em'
          }}
        >
          <img height="16px" width="16px" src="/icons/copy-icon.svg" />
        </TransparentButton>
      ) : null}

      <pre ref={ref}>{children}</pre>
    </PreContainer>
  );
}

type CodeProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLElement>,
  HTMLElement
>;

export function StyledCode({ style, ...props }: CodeProps) {
  const ref = React.useRef<HTMLElement>();

  React.useLayoutEffect(() => {
    hljs.highlightElement(ref.current);
  }, []);

  const handleClick = () => {
    const text = ref.current.textContent;
    if (copySupported()) {
      copyText(text);
    }
  };

  return (
    <code
      style={{ cursor: 'pointer', ...style }}
      onClick={handleClick}
      ref={ref}
      {...props}
    />
  );
}

const PreContainer = styled(Box)`
  border: 1px solid rgba(15, 98, 254, 0.2);
  border-radius: 4px;
  position: relative;
`;

function copyText(text: string) {
  window.navigator.clipboard.writeText(text);
}

function copySupported() {
  return (
    'navigator' in window &&
    typeof window.navigator.clipboard?.writeText === 'function'
  );
}
