import * as React from 'react';
import styled from 'styled-components';
import { FileInput as BaseFileInput } from 'components';
import { Button, Text } from '@we-ui-components/base';
import fileIcon from './file-icon.svg';
import { Box } from 'grommet';
import { ViolationFileInputProps } from './';

interface UploaderProps extends ViolationFileInputProps {
  formats: string[];
  limits?: IFileLimits;
  additionalText?: string;
}

export interface IFileLimits {
  maxFiles?: number;
  maxFileSize?: number;
}

export function ViolationFileUploader({
  value,
  onChange,
  limits,
  formats,
  additionalText
}: UploaderProps) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { maxFiles = 0, maxFileSize = 0 } = limits || {};

  const addFiles = (files: File[]) => {
    const newFiles = files.filter(file => {
      const fileExt = file.name.split('.').slice(-1)[0];
      return formats.includes(`.${fileExt.toLowerCase()}`);
    });

    if (!!maxFileSize) {
      onChange(newFiles.filter(file => file.size <= maxFileSize));
    } else {
      onChange(newFiles);
    }
  };

  return (
    <StyledFileInput
      ref={inputRef}
      value={value}
      onChange={addFiles}
      inputProps={{ accept: formats.join(','), multiple: true }}
    >
      <img src={fileIcon} />

      <Box direction="row" margin={{ top: '12px' }} align="baseline">
        <Text>Перетащите или </Text>

        <LinkButton onClick={() => inputRef.current.click()}>
          загрузите
        </LinkButton>

        {!!maxFiles ? (
          <Text>
            не более {maxFiles % 10 === 1 ? ' 1 файла' : `${maxFiles} файлов`}
          </Text>
        ) : (
          <Text>файлы</Text>
        )}
      </Box>

      <Text>в формате {formats.join(', ')}</Text>
      {additionalText && (
        <Text
          size="small"
          margin={{ top: 'xsmall' }}
          style={{ maxWidth: '450px', textAlign: 'center' }}
        >
          {additionalText}
        </Text>
      )}
    </StyledFileInput>
  );
}

const StyledFileInput = styled(BaseFileInput)`
  border: 1px dashed ${props => props.theme.palette.Primary};

  padding: 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LinkButton = styled(Button).attrs({
  transparent: true,
  color: 'Blue700'
})`
  display: inline;
  padding: 0;

  margin: 0 5px;

  button {
    padding: 0;
    font-size: 15px;
  }
`;
