import { observer } from 'mobx-react';
import * as React from 'react';
import { useStores } from 'stores';
import { AuthStatus as UserAuthStatus } from 'stores/UserStore';
import { statusFetching } from 'we-oauth2/lib/constants/types';
import { UnauthorizedApp } from './UnauthorizedApp';

const AuthorizedApp = React.lazy(() =>
  import(
    /* webpackPrefetch true */
    './AuthorizedApp'
  ).then(({ AuthorizedApp }) => ({
    default: AuthorizedApp
  }))
);

export const AppRoutes = observer(function AppRoutes() {
  const { user } = useStores();

  const status = calculateAuthStatus({
    userStatus: user.status,
    userAuthStatus: user.authStatus
  });

  const isLoading = status === 'loading';
  const isAuthorized = status === 'authorized';

  if (isLoading) return <Loader />;

  return (
    <React.Suspense fallback={<Loader />}>
      {isAuthorized ? <AuthorizedApp /> : <UnauthorizedApp />}
    </React.Suspense>
  );
});

// TODO:
function Loader() {
  return null;
}

type AuthStatusParams = {
  userStatus: statusFetching;
  userAuthStatus: UserAuthStatus;
};

type AuthStatus = UserAuthStatus | 'loading';

function calculateAuthStatus({
  userStatus,
  userAuthStatus
}: AuthStatusParams): AuthStatus {
  const isLoading = () => userStatus === 'fetching';
  return isLoading() ? 'loading' : userAuthStatus;
}
