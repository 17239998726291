import { Box } from 'grommet';
import * as React from 'react';
import { Text } from '@we-ui-components/base';
import * as moment from 'moment';
import { getMonthNameUpperShort } from 'utils/formatDates';

type DateRangeProps = ActualBoxProps & {
  startDate: string;
  endDate: string;
};

type ActualBoxProps = React.ComponentProps<typeof Box>;

export function DateRange({ startDate, endDate, ...props }: DateRangeProps) {
  return (
    <Box gap="0.4em" direction="row" align="center" {...props}>
      <Text size="small">{formatDate(startDate)}</Text>
      <img src="/icons/arrow-right-icon.svg" />
      <Text size="small">{formatDate(endDate)}</Text>
    </Box>
  );
}

export function formatDate(isoStringDate: string) {
  const REPLACE_TOKEN = '=======';
  const mdate = moment(isoStringDate);
  const formattedDate = getMonthNameUpperShort(mdate.month());
  return mdate
    .format(`DD ${REPLACE_TOKEN} в HH:mm`)
    .replace(REPLACE_TOKEN, formattedDate);
}
