import { StoreConstructor } from '../StoreConstructor';
import { computed, observable, reaction, action } from 'mobx';
import { NormalizedError } from 'services/api/errorHandler';
import * as _ from 'lodash';
import { IStores } from 'stores';
import { ListStoreStorage } from './Storage';
import { Fetcher } from './Fetcher';
import { Page } from 'interfaces';
import { Tracker } from './Tracker';

export interface IListStoreOptions {
  pollingInterval?: number;
  paginationData?: IPagination;
  sorter?: string | string[];
  sorters?: ISorters;
  filters?: IFilters;
  isLocal?: boolean;
  persistentStorage?: ListStoreStorage<IDataFlowProps>;
  tracker?: Tracker;
}

type TSorter = 'none' | 'asc' | 'desc';

interface ISorters {
  [name: string]: TSorter;
}

interface IFilters {
  [name: string]: any;
}

export interface IPagination {
  currentPage?: number;
  totalPages?: number;
  totalElements?: number;
  pageSize?: number;
}

export interface IDataFlowProps {
  paginationData?: IPagination;
  sorter?: string | string[];
  sorters?: ISorters;
  filters?: IFilters;
}

export interface OnChangeDataFlowOptions {
  withDebounce?: boolean;
  patchMode?: 'smart' | 'simple';
}

/**
 * Requests and keeps different sorts of list data,
 * allows to sort, filtering and paging the data
 */
export class ListStoreConstructor<T> extends StoreConstructor {
  /**
   * Main source of data
   */
  @observable protected allData: T[] = [];

  /**
   *  current pagination state
   */
  @observable public paginationData: IPagination = {};

  /** probably useless? use cases are not discovered
   * @deprecated
   */
  @observable public sorters: ISorters = {};

  /**
   *  current sorter state
   */
  @observable public sorter: string | string[];

  /**
   * currest filters state
   */
  @observable public filters: IFilters = {};

  /**
   * last error text message
   */
  @observable public fetchError: NormalizedError;

  /**
   * @readonly
   * data request status */
  @computed
  public get fetchStatus() {
    return this.fetcher.status;
  }

  /**
   * provides network functionality
   */
  protected fetcher?: Fetcher<T>;

  /**
   * subscribes to pending transactions
   */
  protected tracker?: Tracker;

  /**
   * contains funcionality for saving state in external storage
   * (URLState, localStorage, other external sources)
   */
  protected URLStorage?: ListStoreStorage<IDataFlowProps>;

  /**
   * Contains reaction for auto updating pagination if `isLocal = true`
   */
  private localPaginationReaction?: any;

  /**
   * If `isLocal` set to `true`,
   * then pagination,sorting and filtering will be handled on client side
   */
  private isLocal = false;

  /**
   * checks if request is in fly right now
   */
  @computed
  public get isPending() {
    return this.fetchStatus === 'fetching';
  }

  constructor(
    stores: IStores,
    endpoint: (params: any) => Promise<Page<T>>,
    options: IListStoreOptions
  ) {
    super(stores);

    const { pollingInterval = 0 } = options;

    this.fetcher = new Fetcher({
      endpointFn: endpoint,
      pollingInterval,
      onError: error => {
        this.fetchError = error;
        this.allData = [];
        this.updatePagination({
          content: [],
          size: 10,
          totalPages: 0,
          totalElements: 0
        });
        this.fetcher.restartPolling();
      },
      getQueryParams: () => (this.isLocal ? null : this.queryParams),
      onSuccess: (res: any) => {
        if (this.isLocal) {
          this.allData = res.content;
        } else {
          this.allData = res.content;
          this.updatePagination(res);
          this.fetcher.restartPolling();
        }

        return res;
      }
    });

    this.sorters = options.sorters || {};
    this.sorter = options.sorter || 'none';
    this.filters = options.filters || {};

    this.isLocal = options.isLocal;

    if (options.persistentStorage) {
      this.URLStorage = options.persistentStorage;
    }

    if (options.tracker) {
      this.tracker = options.tracker;
    }

    /**
     * should be bound for one important use case in @we-ui-components/rc-table
     * TODO: fix @we-ui-components/rc-table and remove this line
     */
    this.onChangeDataFlow = this.onChangeDataFlow.bind(this);

    this.paginationData = {
      currentPage: 1,
      totalPages: 1,
      pageSize: 100
    };

    if (this.isLocal) {
      this.useAutoPaginationUpdate();
    }
  }

  /**
   *  starts polling
   *
   *  if init state is passed as an argument, calls `onChangeDataFlow` with it
   */
  public init(dataFlow: IDataFlowProps = {}) {
    const stateFromStorage = this.URLStorage?.getState() || {};
    const initState = { ...stateFromStorage, ...dataFlow };

    if (!_.isEmpty(initState)) {
      // save init state to instanse state (also updates persistent storage)
      this.onChangeDataFlow(initState, { patchMode: 'simple' });
    } else if (this.URLStorage) {
      // if there state from persistent storage,
      // save default storage to the persistent storage
      this.onChangeDataFlow({}, { patchMode: 'simple' });
    }

    if (this.tracker) {
      this.tracker.start();
    }

    if (!this.isLocal) {
      this.fetcher.startPolling();
    }
  }

  /**
   *  composition of filters, sorters, sorter and pagination
   */
  public get dataFlow() {
    return {
      paginationData: this.paginationData,
      sorters: this.sorters,
      sorter: this.sorter,
      filters: this.filters
    };
  }

  /**
   * updates pagination/sorter/filters state and sends request in `network` mode
   */
  onChangeDataFlow(
    props: IDataFlowProps,
    options: OnChangeDataFlowOptions = {}
  ) {
    const { withDebounce = false, patchMode = 'smart' } = options;

    if (patchMode === 'smart') {
      this.smartStatePatch(props);
    } else {
      this.patchState(props);
    }

    if (!this.isLocal) {
      this.fetch({ withDebounce });
    }

    if (this.URLStorage) {
      this.URLStorage.setState(this.dataFlow);
    }
  }

  private smartStatePatch(state: IDataFlowProps) {
    const {
      paginationData: newPaginationState = {},
      sorter,
      sorters,
      filters
    } = state;

    const paginationData = { ...newPaginationState };

    // recalc total pages and reset current page on change pageSize
    if (paginationData.pageSize !== this.paginationData.pageSize) {
      const totalElements = this.paginationData.totalElements;
      const pageSize = paginationData.pageSize;

      const totalPages = Math.max(Math.ceil(totalElements / pageSize), 1);

      paginationData.currentPage = 1;
      paginationData.totalPages = totalPages;
    }

    // reset current page on new filters
    if (!_.isEmpty(filters)) {
      paginationData.currentPage = 1;
    }

    this.patchState({
      paginationData,
      sorters,
      filters,
      sorter
    });
  }

  @action.bound
  private patchState(state: IDataFlowProps) {
    const { paginationData = {}, sorter, sorters = {}, filters = {} } = state;

    this.paginationData = {
      ...this.paginationData,
      ...paginationData
    };

    this.filters = {
      ...this.filters,
      ...filters
    };

    this.sorters = {
      ...this.sorters,
      ...sorters
    };

    this.sorter = sorter || this.sorter;
  }

  private calcSorter(sorter: string | string[]) {
    if (sorter instanceof Array) return sorter.length > 0 ? sorter : undefined;
    if (sorter === 'none') return undefined;
    return sorter || this.sorter;
  }

  /**
   * extracts pagination config from network response and updates the pagination state
   */
  private updatePagination(res: Partial<Page<T>>) {
    const { totalPages, size, totalElements } = res;

    this.paginationData = {
      ...this.paginationData,
      totalPages,
      totalElements,
      pageSize: size
    };
  }

  /**
   * returns filtered data
   */
  @computed
  private get filteredData() {
    if (!this.isLocal) return this.allData;

    return this.allData.filter(rowData =>
      Object.entries(this.filters)
        .filter(([key, value]) => !!value)
        .every(([key, value]) => {
          if (key === 'search') {
            return Object.values(rowData)
              .filter(rowValue => !!rowValue)
              .some(
                rowValue =>
                  String(rowValue)
                    .toLowerCase()
                    .indexOf(value.toLowerCase()) > -1
              );
          }
          return value instanceof Array
            ? value.includes(rowData[key])
            : rowData[key].indexOf(value) > -1;
        })
    );
  }

  /**
   * returns sorted and filtered data
   */
  @computed
  private get sortedData() {
    if (!this.isLocal) return this.filteredData;
    if (
      !this.sorter ||
      this.sorter === 'none' ||
      this.sorter instanceof Array
    ) {
      return this.filteredData;
    }

    const [index, direction] = this.sorter.split(',');
    const dir = direction === 'asc' ? 1 : -1;
    return this.filteredData.sort((a, b) => {
      return a[index] < b[index] ? dir : -dir;
    });
  }

  /**
   * Returns paginated, sorted and filtered data
   */
  @computed
  get data() {
    if (this.isLocal) {
      const { pageSize, currentPage } = this.paginationData;

      return this.sortedData.splice(pageSize * (currentPage - 1), pageSize);
    } else {
      return this.allData;
    }
  }

  @computed
  private get queryParams() {
    const { currentPage, pageSize } = this.paginationData;
    return {
      size: pageSize,
      page: currentPage - 1,
      ...this.filters,
      sort: this.calcSorter(this.sorter)
    };
  }

  /**
   *  sends request
   */
  fetch = (options: { isSilent?: boolean; withDebounce?: boolean } = {}) => {
    if (!this.isLocal) {
      this.fetcher.stopPolling();
    }
    return this.fetcher.sendRequest(options);
  };

  clear() {
    this.allData = [];
    this.fetchError = null;
    this.fetcher.clear();
  }

  private useAutoPaginationUpdate() {
    if (this.localPaginationReaction) this.localPaginationReaction();

    this.localPaginationReaction = reaction(
      () => this.filteredData,
      () => {
        this.paginationData.totalPages = Math.ceil(
          this.filteredData.length / this.paginationData.pageSize
        );
      }
    );
  }
}
