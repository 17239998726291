import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { LoginPage } from 'pages';
import { BaseContainer, RedirectEx } from 'components';
import { Routes } from 'constants/routes';

export function UnauthorizedApp() {
  return (
    <BaseContainer>
      <Switch>
        <Route exact={true} path={`/${Routes.login}`}>
          <LoginPage />
        </Route>

        <RedirectEx to={`/${Routes.login}`} />
      </Switch>
    </BaseContainer>
  );
}
