import * as React from 'react';
import { Box, BoxProps } from 'grommet';
import { withTheme } from 'styled-components';
import { TStyledChildrenProps } from 'interfaces';
import { CSSProperties, DOMAttributes } from 'react';

type TProps = DOMAttributes<HTMLDivElement> &
  TStyledChildrenProps<BoxProps> & {
    style?: CSSProperties;
    color?: TColorEnum;
  };

type TColorEnum = 'white' | 'green' | 'grey';

const COLOR_MAP: Record<TColorEnum, CSSProperties> = {
  white: {
    borderRadius: 1,
    background: 'white',
    boxShadow: '0px 4px 8px rgba(10, 6, 6, 0.04)',
  },
  green: {
    borderRadius: 1,
    background: 'linear-gradient(270.9deg, #00AA78 1.92%, #00D093 98.19%)',
    boxShadow: '0px 4px 12px rgba(0, 168, 122, 0.2)',
  },
  grey: {
    borderRadius: 1,
    background: 'linear-gradient(270.9deg, #8D8D8D 1.92%, #CBCBCB 98.19%)',
    boxShadow: '0px 4px 8px rgba(10, 6, 6, 0.04)',
  },
};

export const Panel: React.FC<TProps> = withTheme(
  ({ children, theme, style, color = 'white', ...rest }: TProps) => (
    <Box style={{ ...style, ...COLOR_MAP[color] }} {...rest}>
      {children}
    </Box>
  ),
);
