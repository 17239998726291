import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { StyledButton, Card } from 'ui';
import { Box } from 'grommet';
import { useStores } from 'stores';
import { OptionsListItem } from './OptionsListItem';
import { OptionForm, FormData as OptionFormData } from './OptionForm';
import { OptionApiDto } from 'interfaces/apiEntities';

type BoxProps = React.ComponentProps<typeof Box>;

type OptionsProps = BoxProps & {
  questionNum: number;
  options: OptionApiDto[];
};

export const Options = observer<OptionsProps>(function Options({
  questionNum,
  options
}) {
  const { activePoll, actionModals } = useStores();

  const handleAddClick = () => {
    actionModals.open(OptionForm, {
      title: 'Добавление варианта ответа',
      applyText: () => <span>Создать</span>,
      closeText: () => <span>Отменить</span>,
      onApply: async (data: OptionFormData) =>
        activePoll
          .createOption(questionNum, data)
          .then(() => activePoll.fetchQuestionsLight()),
      noValidation: true
    });
  };

  // if (!activePoll.questionListStore.optionListStores.has(questionNum)) {
  //   return null;
  // }

  // const store = activePoll.getOptionsListStore(questionNum);
  //
  // if (store.fetchStatus === 'error') return <span>error</span>;
  // if (store.fetchStatus !== 'success') return <span>loading</span>;

  return (
    <Box align="start" width="100%">
      {options.map(option => (
        <OptionsListItem
          key={option.num}
          questionNum={questionNum}
          option={option}
        />
      ))}

      {activePoll.isEditable ? (
        <StyledButton
          onClick={handleAddClick}
          variant="secondary"
          style={{ marginTop: '1em' }}
        >
          <img
            src="/icons/add-icon-primary.svg"
            style={{ marginRight: '0.5em' }}
          />
          Добавить вариант ответа
        </StyledButton>
      ) : null}
    </Box>
  );
});
