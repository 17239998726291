import * as React from 'react';

export const ExitIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="22"
    height="19"
    viewBox="0 0 22 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 6V1.5C12 0.673 11.327 0 10.5 0H1.5C0.673 0 0 0.673 0 1.5V17.5C0 18.327 0.673 19 1.5 19H10.5C11.327 19 12 18.327 12 17.5V13H11V17.5C11 17.775 10.776 18 10.5 18H1.5C1.224 18 1 17.775 1 17.5V1.5C1 1.225 1.224 1 1.5 1H10.5C10.776 1 11 1.225 11 1.5V6H12Z"
      fill={props.fill || '#0A1E32'}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.396 5.854L19.543 9H7V10H19.543L16.396 13.146L17.104 13.854L21.457 9.5L17.104 5.146L16.396 5.854Z"
      fill={props.fill || '#0A1E32'}
    />
  </svg>
);
