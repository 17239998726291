import { Box } from 'grommet';
import * as React from 'react';
import { useTheme } from 'utils/hooks';
import logoSVG from './components/logo.svg';
import { useStores } from 'stores';
import { LogoutButton } from './components/LogoutButton';
import styled from 'styled-components';

export function Head() {
  const { user } = useStores();

  const { container } = useTheme();
  const { minWidth, maxWidth } = container;

  return (
    <HeaderContainer>
      <Box
        direction="row"
        align="center"
        pad={{ horizontal: '4em' }}
        style={{
          minWidth,
          maxWidth,
          margin: '0 auto',
          height: 44,
          width: '100%'
        }}
      >
        <img style={{ marginRight: 20 }} src={logoSVG} />

        {user.isAuthorized ? (
          <Box
            direction="row"
            justify="end"
            align="center"
            margin={{ left: 'auto' }}
          >
            <LogoutButton />
          </Box>
        ) : null}
      </Box>
    </HeaderContainer>
  );
}

// minWidth,
//             maxWidth,
//             margin: '0 auto',
//             height: 56,
//             width: '100%'

const HeaderContainer = styled(Box)`
  background-color: ${props => props.theme.palette.White};
  overflow: visible;
  box-shadow: 0px 4px 8px rgba(10, 6, 6, 0.04);
`;
