import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { OptionApiDto } from 'interfaces/apiEntities';
import { Card, TransparentButton, Gallery } from 'ui';
import { Text } from '@we-ui-components/base';
import { Box } from 'grommet';
import { useStores } from 'stores';
import { OptionForm, FormData as OptionFormData } from './OptionForm';

interface OptionsListItemProps {
  option: OptionApiDto;
  questionNum: number;
}

export const OptionsListItem = observer<OptionsListItemProps>(
  function OptionsListItem({ option, questionNum }) {
    const { actionModals, activePoll } = useStores();

    const handleEditClick = () => {
      actionModals.open(OptionForm, {
        title: 'Редактирование варианта ответа',
        applyText: () => <span>Сохранить</span>,
        closeText: () => <span>Отменить</span>,
        onApply: async (data: OptionFormData) =>
          activePoll
            .editOption(questionNum, option.num, data)
            .then(() => activePoll.fetchQuestionsLight()),
        noValidation: true,
        initData: option
      });
    };

    const handleRemoveClick = () => {
      actionModals.open(
        () => (
          <Box>
            <p>
              Вы уверены, что хотите удалить вариант <b>{option.name}</b>?
            </p>
          </Box>
        ),
        {
          applyText: () => <span>Удалить</span>,
          closeText: () => <span>Отмена</span>,
          applyButtonProps: {
            color: 'white',
            bgColor: 'hsl(346, 99%, 53%)'
          },
          noValidation: true,
          onApply: async () => {
            activePoll
              .deleteOption(questionNum, option.num)
              .then(() => activePoll.fetchQuestionsLight());
          }
        }
      );
    };

    return (
      <Card
        margin={{ top: '1em' }}
        pad="2em"
        width="100%"
        style={{
          border: '1px solid rgba(15, 98, 254, 0.2)',
          position: 'relative'
        }}
      >
        {activePoll.isEditable ? (
          <Box
            direction="row"
            gap="0.5em"
            style={{ position: 'absolute', top: '2em', right: '2em' }}
          >
            <TransparentButton onClick={handleEditClick}>
              <img src="/icons/edit-icon.svg" />
            </TransparentButton>

            <TransparentButton onClick={handleRemoveClick}>
              <img src="/icons/delete-icon.svg" />
            </TransparentButton>
          </Box>
        ) : null}
        {option.files.length > 0 ? <Gallery srcList={option.files} /> : null}
        <Text margin={{ vertical: '1em' }} fontWeight="700">
          {option.name}
        </Text>
        <Text size="0.8em">{option.description}</Text>
      </Card>
    );
  }
);
