import * as React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { useStores } from 'stores';

import { PollListItem } from './PollListItem';
import { Spinner } from 'grommet';
import { Error as ErrorUI } from 'ui';

export const PollsGrid = observer(function PollsGrid() {
  const { polls } = useStores();

  React.useEffect(() => {
    polls.fetch();
  }, []);

  if (polls.fetchStatus === 'fetching') return <Spinner />;
  if (polls.fetchStatus === 'error')
    return (
      <ErrorUI error="Произошла ошибка при получении списка голосований. Перезагрузите страницу" />
    );

  return (
    <Grid>
      {polls.data?.map(poll => (
        <PollListItem key={poll.id} poll={poll} />
      ))}
    </Grid>
  );
});

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 32px;
`;
