import * as React from 'react';
import { Box } from 'grommet';
import { BoxProps } from 'grommet/es6';
import { TRole, PROJECT_STATUS } from 'interfaces';
import { useState, useCallback } from 'react';
import {
  TabContainer,
  TabItem,
  SubTabContainer,
  SubTabItem
} from './components';
import styled, { withTheme } from 'styled-components';
import { IStyledProps } from 'themes';

export const Tabs: React.FC<IProps & BoxProps> = ({
  onChange,
  selected,
  tabs,
  small,
  children,
  projectsCount,
  ...rest
}) => {
  return (
    <Box direction="row" {...rest}>
      {tabs.map(item => (
        <Tab
          key={item.id}
          onChange={onChange}
          selected={selected}
          small={small}
          projectsCount={projectsCount}
          {...item}
        />
      ))}
      {children}
    </Box>
  );
};

const CountTabProjects: React.FC<ICountTabProps> = ({id, projectsCount}):JSX.Element => {
  let count
  if(projectsCount) {
    if(id === 'ALL'){
      count = Object.values(projectsCount).reduce((accumulator, current) => accumulator + current, 0)
    }
    if(projectsCount[id]){
      count = projectsCount[id]
    }
  }
  return count ? <span style={{marginLeft: '2px'}}>({count})</span> : null
}

const Tab: React.FC<ITabProps & IStyledProps> = withTheme(
  ({
    selected,
    id,
    text,
    onChange,
    small,
    children,
    projectsCount,
    disabled
  }: ITabProps & IStyledProps) => {
    const [showSubMenu, setShowSubMenu] = useState<boolean>(false);

    const onClick = useCallback(
      (id: string) => {
        if (disabled) {
          return;
        }

        id && onChange && onChange(id);
        // if (!!children) {
        //   setShowSubMenu(false);
        // }
      },
      [showSubMenu, disabled]
    );

    const onMouseEnter = useCallback(() => {
      if (!!children) {
        setShowSubMenu(true);
      }
    }, [showSubMenu]);

    const onMouseLeave = useCallback(() => {
      setShowSubMenu(false);
    }, [showSubMenu]);

    return (
      <TabContainer
        active={
          selected === id ||
          (children && children.some(item => selected === item.id))
        }
        small={small}
        onClick={() => onClick(id)}
        // onMouseEnter={() => onMouseEnter()}
        onMouseLeave={onMouseLeave}
      >
        <TabItem onMouseEnter={() => onMouseEnter()}>
          <span>{text}</span>
          <CountTabProjects id={id} projectsCount={projectsCount} />
        </TabItem>
        {showSubMenu && (
          <SubTabContainer>
            {children.map(item => (
              <SubTabItem
                key={item.id}
                active={selected === item.id}
                onClick={() => onClick(item.id)}
              >
                {item.text}
              </SubTabItem>
            ))}
          </SubTabContainer>
        )}
      </TabContainer>
    );
  }
);

export namespace Tabs {
  export type TTabsProps = IProps;
  export type TTabProps = ITabOptions;
}

export interface ITabProps extends ITabOptions, React.ComponentProps<any> {
  selected?: string;
  onChange?: (id: string) => void;
  small?: boolean;
  projectsCount?: Record<PROJECT_STATUS, number>
}

export interface ITabOptions {
  text: string | React.ReactNode;
  id?: string;
  disabled?: boolean;
  roles?: TRole[];
  children?: Array<ITabOptions>;
  projectsCount?: Record<PROJECT_STATUS, number>;
}

interface IProps {
  selected?: string;
  onChange?: (id: string) => void;
  tabs: Array<ITabOptions>;
  small?: boolean;
  projectsCount?: Record<PROJECT_STATUS, number>
}

interface ICountTabProps {
  id: string,
  projectsCount?: Record<PROJECT_STATUS, number>
}

export const SlimTabs = styled(Tabs)`
  height: auto;
  max-width: 100%;
  overflow-x: auto;

  & ${TabItem} {
    padding: 0;
    height: 100%;
    width: max-content;
  }

  & ${TabContainer} {
    padding-bottom: 13px;

    :not(:first-of-type) {
      margin-left: 16px;
    }
  }
`;
