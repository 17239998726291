import * as React from 'react';
import styled from 'styled-components';
import { StyledButton } from '../StyledButton';
import { GalleryCssTheme, galleryTheme as theme } from './galleryTheme';
import {ENDPOINTS} from "services/endpoints";
import { api } from 'services/api';

type GalleryProps = {
  srcList: ImgSrcInfo[];
};

export function Gallery({ srcList }: GalleryProps) {
  return (
    <GalleryContainer>
      {srcList.map(id => {
        const link = `${ENDPOINTS.files()}/${id}?access_token=${api.getToken()}`
        return (
          <ImgPreview
            loading="lazy"
            key={id}
            src={link}
            {...({} as any)} // <-- styled components types does not recognize `loading="lazy"` attr
          />
        )
      })}
    </GalleryContainer>
  );
}

const ImgPreview = styled.img`
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
`;

const GalleryContainer = styled.div`
  ${GalleryCssTheme};

  min-height: ${theme.coverHeight};

  display: grid;

  gap: ${theme.gapVertical} ${theme.gapHorizontal};
  grid-auto-flow: column;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: ${theme.coverWidth} repeat(
      auto-fit,
      ${theme.previewWidth}
    );

  ${ImgPreview} {
    width: ${theme.previewWidth};
    height: ${theme.previewHeight};

    :first-child {
      width: ${theme.coverWidth};
      height: ${theme.coverHeight};
      grid-row: 1 / span 2;
    }
  }
`;

type ImgSrcInfo = string;
