import { POLL_STATUS } from 'interfaces/apiEntities';
import * as React from 'react';
import styled from 'styled-components';
import { getConfigByKey } from './badgeConfig';

export { PollStatusBadge };

interface PollStatusBadgeProps {
  status: POLL_STATUS;
}

function PollStatusBadge({ status }: PollStatusBadgeProps) {
  return <Badge status={status}>{getContent(status)}</Badge>;
}

const Badge = styled.span<{ status: POLL_STATUS }>`
  color: ${getColor};
  background-color: ${getBgColor};
  border-radius: 2px;
  font-weight: 700;
  font-family: Montserrat;
  font-size: 11px;
  padding: 2px 4px;
`;

function getColor({ status }: PollStatusBadgeProps) {
  const config = getConfigByKey(status);
  return config.color;
}

function getBgColor({ status }: PollStatusBadgeProps) {
  const config = getConfigByKey(status);
  return config.backgroundColor;
}

function getContent(status: POLL_STATUS) {
  const config = getConfigByKey(status);
  return config.content;
}
