import { Text } from '@we-ui-components/base';
import {
  MobxForm,
  Form,
  Input,
  TextArea,
  isRequired,
  DateTimePicker
} from '@we-ui-components/form';
import { Routes } from 'constants/routes';
import { Box } from 'grommet';
import { PollCreateRequest } from 'interfaces/apiEntities';
import * as React from 'react';
import { useHistory } from 'react-router';
import { useStores } from 'stores';
import { ActionsContainer } from './ActionsContainer';
import { StyledButton } from 'ui';
import { PollForm, FormData as PollFormData } from './PollForm';

export function CreatePollForm() {
  const ref = React.useRef<MobxForm>();
  const history = useHistory();
  const { polls } = useStores();

  const handleSubmit = async () => {
    const data: PollFormData = await ref.current.validateFields();
    const requestData: PollCreateRequest = {
      name: data.name,
      description: data.description,
      votingEndDate: data.votingEndDate.toISOString(),
      votingStartDate: data.votingStartDate.toISOString()
    };

    const response = await polls.createPoll(requestData);

    history.push(`/${Routes.polls}/${response.id}`);
  };

  const handleCancelClick = () => {
    history.push(`/${Routes.polls}`);
  };

  return (
    <Box pad="large" width={{ max: '736px' }} background="white">
      <PollForm ref={ref} />

      <ActionsContainer>
        <StyledButton variant="primary" onClick={handleSubmit}>
          Создать голосование
        </StyledButton>

        <StyledButton variant="secondary" onClick={handleCancelClick}>
          Отменить
        </StyledButton>
      </ActionsContainer>
    </Box>
  );
}
