import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react-lite';
import { Box } from 'grommet';
import {
  Form,
  MobxForm,
  Input,
  TextArea,
  isRequired,
  FileInput,
  createField
} from '@we-ui-components/form';
import { FieldWrapper, FileInput as BFileInput } from '@we-ui-components/base';
import { TActionModalProps } from 'components';
import { Pr } from 'utils/reflection';
import { maxLength } from 'utils/validators';
import { OptionApiDto } from 'interfaces/apiEntities';
import { FilesIdInput } from './FileInput';

// TODO: files

export type FormData = {
  name: string;
  description: string;
};

type OptionFormProps = TActionModalProps<OptionApiDto>;

export const OptionForm = observer<OptionFormProps>(
  function OptionForm(props, forwardedRef) {
    const formRef = React.useRef<MobxForm>();

    const formData = useLocalStore(() => {
      if (props.actionData.data) {
        const data = props.actionData.data;

        return {
          name: data.name,
          description: data.description,
          files: data.files || []
        };
      }

      return {
        name: '',
        description: '',
        files: []
      };
    });

    const handleSubmit = async () => {
      const data: FormData = await formRef.current.validateFields();
      return data;
    };

    React.useImperativeHandle(forwardedRef, () => ({
      onValidate: handleSubmit
    }));

    const hasIds = formData.files.some(i => typeof i === 'string');

    return (
      <Box margin={{ top: '1em' }}>
        <Form ref={formRef} data={formData} {...({} as any)}>
          <Input
            name={Pr<FormData>('name')}
            placeholder="Заголовок"
            rules={[isRequired, maxLength(255)]}
          />
          <TextArea
            name={Pr<FormData>('description')}
            rules={[isRequired, maxLength(1000)]}
            placeholder="Описание"
            rows={8}
            {...({} as any)}
          />
          {!hasIds && (
            <FilesIdInput
              name="files"
              withPreview
              limits={{ maxFiles: 3 }}
              formats={['.jpg', 'png']}
              rules={[]}
            />
          )}
        </Form>
      </Box>
    );
  },
  { forwardRef: true }
);

// const FileInput = createField<any>({
//   wrapper: FieldWrapper,
//   wrapperParams: { hasFeedback: true },
//   component: (props: any) => <BFileInput size="full" {...props} />,
// });
