import { Page, PollApiDto, PollCreateRequest } from 'interfaces/apiEntities';
import { ENDPOINTS } from '.';
import { api } from './api';

function getPollList(params?: Record<string, any>): Promise<Page<PollApiDto>> {
  return api.get<Page<PollApiDto>>(ENDPOINTS.polls(), params);
}

function createPoll(newPoll: PollCreateRequest): Promise<PollApiDto> {
  return api.post<PollApiDto>(ENDPOINTS.polls(), newPoll);
}

function getPollById(id: string): Promise<PollApiDto> {
  return api.get<PollApiDto>(ENDPOINTS.pollById(id));
}

function editPoll(id: string, data: PollCreateRequest): Promise<PollApiDto> {
  return api.patch<PollApiDto>(ENDPOINTS.pollById(id), data);
}

function publishPoll(id: string): Promise<PollApiDto> {
  return api.post<PollApiDto>(ENDPOINTS.publishPollById(id), { id });
}

function uploadFile(
  file: File,
  // type: 'PUBLIC' | 'PRIVATE' | 'RESULT'
): Promise<{ id: string }> {
  const formData = new FormData();
  formData.append('file', file);
  // formData.append('type', type);

  return api.post(ENDPOINTS.files(), formData);
}

export { getPollList, createPoll, getPollById, editPoll, publishPoll, uploadFile };
