import * as React from 'react';
import { Box } from 'grommet';
import { Pre, StyledCode } from 'ui';

export function GeneratedCodeModal({ pollId }) {
  return (
    <Box pad="1em">
      <p>
        Уникальный ID вашего голосования:{' '}
        <StyledCode style={{ whiteSpace: 'nowrap' }}>"{pollId}"</StyledCode>.
      </p>
      <p>
        Для проведения голосования на вашем сайте, используйте SDK со следующими
        параметрами:
      </p>

      <Pre>{getCode(pollId)}</Pre>
    </Box>
  );
}

function getCode(pollId: string, url = 'https://we-vote-dev.weintegrator.com') {
  return `
  const voting = voteSdk.create({
    url: "${url}",
    pollId: "${pollId}",
  });
`;
}
