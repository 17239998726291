import * as _ from 'lodash';
import { action, computed, observable } from 'mobx';
import { UserInfo } from 'models';
import { UserStore } from 'we-oauth2';
import stores, { IStores, onAuthSuccessCb } from 'stores';
import { createError, formatPersonName, HttpResponseError } from 'utils';
import { RegistrationRequest, TRole } from 'interfaces';
import { Routes } from 'constants/routes';
import { api, userService } from 'services';
import { RIGHTHOLDER_ROLES } from '../constants';
import * as bcrypt from 'bcryptjs';
import get from 'lodash.get';

const defaults = {};

export class UserStoreEx extends UserStore<UserInfo> {
  public stores: IStores;

  @observable public isPageScrolling = false;

  @observable public encryptPassword = '';

  @observable public encryptedContent = '';

  constructor(props) {
    super(props);
  }

  @action.bound
  public updateUserInfo() {
    return this.userService
      .getUserInfo()
      .then(userInfo => {
        this.userInfo = userInfo;
      })
      .catch(error => {
        this.status = 'error';

        const errorCode = get(error, 'response.body.error');

        if (errorCode) {
          return Promise.reject(createError('AUTH_ERROR', errorCode));
        }

        return Promise.reject(error);
      });
  }

  @action.bound
  public async saveEncryptPassword(password: string) {
    this.encryptPassword = password;
    this.syncLocalStorage();

    if (!this.encryptedContent) {
      await this.saveEncryptedContent(bcrypt.hashSync(password));
    }
  }

  @computed
  public get authStatus(): AuthStatus {
    if (this.userInfo?.accountId) {
      if (this.userInfo.businessRoles.length > 0) return 'authorized';
      return 'non-verified';
    }

    return 'unauthenticated';
  }

  @computed
  public get roles(): TRole[] {
    return this.userInfo
      ? this.userInfo.businessRoles.length
        ? this.userInfo.businessRoles
        : ['VOTER']
      : [];
  }

  @computed
  public get isRightholderEmployee() {
    return this.isContainOneOfRoles(RIGHTHOLDER_ROLES);
  }

  public get formattedName() {
    return formatPersonName({
      lastName: this.userInfo.lastName,
      firstName: this.userInfo.firstName,
      patronymic: this.userInfo.meta?.patronymic
    });
  }

  @observable public isLogouting = false;

  @observable public redirectUrl: string = '';

  @action.bound
  public showErrorEx(error: HttpResponseError) {
    this.status = 'error';

    const errorCode = _.get(error, 'response.body.error');

    if (errorCode) {
      return Promise.reject(createError('AUTH_ERROR', errorCode));
    }

    return Promise.reject(error);
  }

  @action
  public async loginEx(user: string, password: string) {
    return this.login(user, password).catch(this.showErrorEx);
  }

  @action
  public logoutEx(): Promise<void> {
    this.isLogouting = true;
    this.redirectUrl = '';

    return this.logout()
      .then(() => {
        this.isLogouting = false;
        Object.values(stores).forEach(store => store.clear && store.clear());

        this.redirectUrl = null;
        this.stores.routing.push(`/${Routes.landing}`);
      })
      .catch(this.showErrorEx);
  }

  get firstName(): string {
    return this.userInfo ? this.userInfo.firstName : '';
  }

  get lastName(): string {
    return this.userInfo ? this.userInfo.lastName : '';
  }

  @action refreshUserInfo() {
    this.userService.getUserInfo().then();
  }

  @action
  public setUser(data: Partial<UserStoreEx>) {
    Object.assign(this, data); // mobx set
  }

  @action
  public reset() {
    Object.assign(this, defaults);
  }

  public isContainRoles(roles: TRole[]) {
    return roles.every(role => this.roles.includes(role));
  }

  public isContainOneOfRoles(roles: TRole[]) {
    return roles.some(role => this.roles.includes(role));
  }

  public saveRedirectUrl(url: string) {
    if (!this.isAuthorized && url) {
      this.redirectUrl = url;
    }
  }

  private redirect() {
    const url =
      this.stores.routing.location.pathname +
      this.stores.routing.location.search;
    if (this.redirectUrl && url !== this.redirectUrl) {
      this.stores.routing.push(this.redirectUrl);
    }
  }

  @action
  public onAuthSuccess: onAuthSuccessCb = async (userInfo: UserInfo) => {
    if (this.authStatus === 'authorized') {
      if (this.isContainRoles(['VOTER'])) {
        // await this.getEncryptedContent();

        const session = localStorage.getItem('ibudget_voiting_session');

        const sessionObj = JSON.parse(session);

        if (
          sessionObj &&
          sessionObj.encryptPassword &&
          sessionObj.accountId === this.userInfo.accountId
        ) {
          this.encryptPassword = sessionObj.encryptPassword;
        } else {
          this.clearEncryptPassword();
        }
      }

      this.stores.catalogs.fetchStatus = 'success';

      Promise.resolve().then(() => this.redirect());
    }
  };

  registration = (user: RegistrationRequest) => userService.registration(user);

  verify = async (code: string) => {
    await userService.confirmRegistration({
      confirmationCode: code,
      accountId: this.userInfo.accountId
    });

    api.clearAuthToken();

    this.init();
  };

  getSolvers() {
    return userService.solvers();
  }

  getSolversByName(name: string) {
    return userService.solversByName(name);
  }

  private syncLocalStorage() {
    localStorage.setItem(
      'ibudget_voiting_session',
      JSON.stringify({
        accountId: this.userInfo.accountId,
        encryptPassword: this.encryptPassword
      })
    );
  }

  @action.bound
  public async saveEncryptedContent(password: string) {
    await userService.saveEncryptedContent({ encryptedContent: password });
  }

  @action.bound
  public async clearEncryptPassword() {
    this.encryptPassword = '';
    this.syncLocalStorage();
  }

  @action.bound
  public async getEncryptedContent() {
    try {
      this.encryptedContent = await userService.getEncryptedContent();
    } catch (e) {
      this.encryptedContent = '';
    }
  }
}

export type AuthStatus = 'authorized' | 'unauthenticated' | 'non-verified';
