import {
  Page,
  QuestionApiDto,
  QuestionCreateRequest
} from 'interfaces/apiEntities';
import { api, ENDPOINTS } from '.';

function getQuestions(
  pollId: string,
  params?: Record<string, any>
): Promise<Page<QuestionApiDto>> {
  // @ts-ignore TODO: remove after correct work with options for questions
  return api.get<Page<QuestionApiDto>>(ENDPOINTS.questions(pollId), {...params, expandOptions: true });
}

function createQuestion(
  pollId: string,
  question: QuestionCreateRequest
): Promise<QuestionApiDto> {
  return api.post<QuestionApiDto>(ENDPOINTS.questions(pollId), question);
}

function deleteQuestion(pollId: string, questionNum: number) {
  return api.del(ENDPOINTS.questionByNum(questionNum, pollId));
}

function editQuestion(
  pollId: string,
  questionNum: number,
  question: QuestionCreateRequest
): Promise<QuestionApiDto> {
  return api.patch<QuestionApiDto>(
    ENDPOINTS.questionByNum(questionNum, pollId),
    question
  );
}

export { getQuestions, createQuestion, deleteQuestion, editQuestion };
