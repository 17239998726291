import { App } from './app';
import * as React from 'react';
import { render } from 'react-dom';

main();

async function main() {
  if (process.env.NODE_ENV === 'development') {
    applyHMR();
  }
  renderApp();
}

function applyHMR() {
  if (module.hot) {
    module.hot.accept();
  }
}

function renderApp() {
  render(<App />, document.getElementById('root'));
}

declare const module;
