export interface IStyledProps {
  theme?: ITheme;
}

export interface ITheme {
  palette: IPalette;
  container: IBaseContainer;
}

export interface IPalette {
  Basic1000: string;
  Basic900: string;
  Basic700: string;
  Basic500: string;
  Basic400: string;
  Basic300: string;
  Basic200: string;
  Basic100: string;

  Blue900: string;
  Blue800: string;
  Blue700: string;
  Blue600: string;
  Blue200: string;

  Red500: string;
  Orange500: string;
  Green500: string;

  StandardBlack: string;
  StandardWhite: string;

  Background: string;
  Shadow: string;

  BlackText: string;
  GreyText: string;
  DarkGray: string;
  White: string;
  Grey: string;
  LightGrey: string;
  Black: string;
  Blue: string;
  Purple: string;
  Green: string;
  Red: string;
  Orange: string;
  Yellow: string;
}

export interface IBaseContainer {
  minWidth?: string;
  maxWidth?: string;
}

const palette = {
  Basic1000: '#2F3542',
  Basic900: '#4B5364',
  Basic700: '#8D8D8D',
  Basic500: '#959DAE',
  Basic400: '#AFB8CC',
  Basic300: '#DFE2EA',
  Basic200: '#EBEDF2',
  Basic100: '#F3F3F3',

  Blue900: '#00488D',
  Blue800: '#204080',
  Blue700: '#3b33b0',
  Blue600: '#0062C0',
  Blue200: '#C2D6EA',

  Primary: '#0F62FE',

  Red500: '#E5494D',
  Orange500: '#EE9F18',
  Green500: '#00AA78',

  StandardWhite: '#FFFFFF',
  StandardBlack: '#000000',

  Background: '#F2F4F8',
  Shadow: 'rgba(115, 115, 146, 0.16)',

  BlackText: '#0a0606',
  GreyText: '#8D8D8D',
  DarkGray: '#525252',
  White: '#FFFFFF',
  Grey: '#8D8D8D',
  LightGrey: '#F3F3F3',
  LightGrey50: '#EFEFEF', // Ligth Grey 0.5
  LightGrey20: "#F2F4F8",
  Black: '#0A0606',
  Blue: '#204080',
  Purple: '#3B33B0',
  Green: '#00AA78',
  Red: '#F0222B',
  Orange: '#F8B700',
  Yellow: '#F0B51D'
};

export const mainTheme = {
  // storybook theming
  colorPrimary: 'black',
  colorSecondary: 'lightblue',

  // UI
  appBg: 'white',
  appContentBg: 'white',
  appBorderColor: 'grey',
  appBorderRadius: 4,

  // Typography
  fontBase: 'Inter, sans-serif',
  fontCode: 'monospace',

  // Text colors
  textColor: palette.BlackText,
  titleColor: palette.BlackText,
  textInverseColor: palette.Basic700,

  // Toolbar default and active colors
  barTextColor: 'white',
  barSelectedColor: 'black',
  barBg: '#9a9a9a',

  // Form colors
  inputBg: 'white',
  inputBorder: '#D6D6D6',
  inputTextColor: 'black',
  inputBorderRadius: 4,

  brandTitle: 'RosCosmos',

  // grommet styling

  global: {
    colors: {
      brand: palette.Basic700
    },
    focus: {
      border: {
        color: 'transparent'
      }
    },
    edgeSize: {
      none: '0px',
      hair: '1px',
      xxsmall: '6px',
      xsmall: '12px',
      small: '16px',
      medium: '24px',
      large: '32px',
      xlarge: '40px',
      responsiveBreakpoint: 'small'
    }
  },

  palette,

  sizes: {
    linear: {
      small: '100px',
      medium: '161px',
      large: '223px',
      xlarge: '320px',
      xxlarge: '384px',
      full: '100%',
      auto: 'auto'
    },
    text: {
      xxsmall: '11px',
      xsmall: '13px',
      small: '14px',
      medium: '16px',
      large: '18px',
      xlarge: '22px'
    },
    title: {
      xxsmall: '16px',
      xsmall: '18px',
      small: '24px',
      medium: '28px',
      large: '34px',
      xlarge: '42px'
    },
    defaults: {
      linear: 'auto'
    }
  },

  fonts: {
    title: 'Inter',
    fontWeight: 500,
    fontWeightBold: 700
  },

  container: {
    minWidth: '320px',
    maxWidth: '1440px'
  },

  styled: {
    button: {
      fontSize: '13px',
      padding: '16px',
      borderRadius: '2px',
      bgColor: palette.Primary,
      border: `1px solid ${palette.Primary}`,

      disableBgColor: palette.Blue200,
      bgHoverColor: palette.Blue200
    },

    tabs: {
      activeBorderBottomColor: palette.Blue800,
      activeBorderBottomWidth: 2,
      tab: {
        color: 'black',
        colorActive: palette.Blue800,
        backgroundColor: 'transparent',
        backgroundColorActive: 'transparent',
        border: 'none',
        letterSpacing: '0',
        fontSize: '15px',
        padding: '20px 0',
        margin: '0 32px 0 0'
      }
    },

    input: {
      bgColor: palette.LightGrey20,
      textColor: 'black',
      borderColor: palette.Grey,
      borderRadius: '2px',
      minHeight: '45px',
      height: '56px',
      padding: '12px',
      innerPadding: '0',
      labelColor: palette.Grey,
      customPlaceholder: true,
      borderFocusedColor: palette.Blue600,
      borderErrorColor: palette.Red,
      borderSuccessColor: palette.Green,
      disabledColor: palette.Basic400,
      customDDSeparator: {
        display: 'none'
      },
      ddIndicatorProps: {
        pad: '8px',
        color: palette.Blue,
        size: '16px'
      }
    },

    colors: {
      colorPrimary: palette.Blue800,
      colorSecondary: 'white'
    }
  }
};
