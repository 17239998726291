import { Text } from '@we-ui-components/base';
import {
  MobxForm,
  Form,
  Input,
  TextArea,
  isRequired,
  DateTimePicker
} from '@we-ui-components/form';
import { Box } from 'grommet';
import { useLocalStore } from 'mobx-react-lite';
import * as React from 'react';
import { Pr } from 'utils/reflection';
import { isAfterDateField, maxLength } from 'utils/validators';
import { observer } from 'mobx-react-lite';
import { PollApiDto } from 'interfaces/apiEntities';

export type FormData = {
  name: string;
  votingStartDate: Date;
  votingEndDate: Date;

  description?: string;
};

interface PollFormProps {
  initData?: PollApiDto;
}

export const PollForm = observer<PollFormProps>(
  function PollForm(props, ref) {
    const formData = useLocalStore<FormData>(() => {
      if (props.initData) {
        const { initData } = props;

        return {
          name: initData.name,
          votingStartDate: new Date(initData.votingStartDate),
          votingEndDate: new Date(initData.votingEndDate),
          description: initData.description || ''
        };
      }

      return {
        name: '',
        votingStartDate: new Date(),
        votingEndDate: new Date(Date.now() + 3 * 60 * 60 * 1000),
        description: ''
      };
    });

    return (
      <Box>
        <Form ref={ref} data={formData} {...({} as any)}>
          <Input
            name={Pr<FormData>('name')}
            placeholder="Наименование голосования*"
            rules={[isRequired, maxLength(255)]}
          />
          <TextArea
            placeholder="Описание голосования"
            name={Pr<FormData>('description')}
            rules={[maxLength(1000)]}
            rows={5}
            {...({} as any)}
          />

          <Text
            size="13px"
            margin={{ bottom: '20px' }}
            fontFamily="Montserrat"
            fontWeight="600"
          >
            Даты голосования*
          </Text>

          <DateTimePicker
            name={Pr<FormData>('votingStartDate')}
            label="Начало голосования*"
            rules={[isRequired]}
          />
          <DateTimePicker
            name={Pr<FormData>('votingEndDate')}
            label="Окончание голосования*"
            rules={[
              isRequired,
              isAfterDateField(
                Pr<FormData>('votingStartDate'),
                0,
                'Дата должна быть позже даты начала приема'
              )
            ]}
          />
        </Form>
      </Box>
    );
  },
  {
    forwardRef: true
  }
);
