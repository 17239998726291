import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react-lite';
import { Box } from 'grommet';
import { Form, MobxForm, Input, TextArea } from '@we-ui-components/form';
import { StyledButton } from 'ui';
import { TActionModalProps } from 'components';
import { Text, Button } from '@we-ui-components/base';
import { Pr } from 'utils/reflection';
import { maxLength, isRequired } from 'utils/validators';
import { QuestionApiDto } from 'interfaces/apiEntities';
import { useStores } from 'stores/index';
import { Error } from 'ui';

export type FormData = {
  name: string;
  description: string;
};

type QuestionFormProps = TActionModalProps<QuestionApiDto>;

export const QuestionForm = observer<QuestionFormProps>(
  function QuestionForm(props, forwardedRef) {
    const formRef = React.useRef<MobxForm>();
    const { activePoll } = useStores();
    const formData = useLocalStore(() => {
      if (props.actionData.data) {
        const data = props.actionData.data;

        return {
          name: data.name,
          description: data.description
        };
      }

      return {
        name: '',
        description: ''
      };
    });

    const handleSubmit = async () => {
      const data: FormData = await formRef.current.validateFields();
      return data;
    };

    // React.useEffect(() => {
    //   props.onValidate.callback = handleSubmit;
    // }, []);

    React.useImperativeHandle(forwardedRef, () => ({
      onValidate: handleSubmit
    }));
    //
    // React.useEffect(() => {
    //   props.onValidate.callback = async () => {
    //     return await formRef.current.validateFields();
    //   }
    // }, []);
    //
    // React.useImperativeHandle(forwardedRef, () => formRef);

    return (
      <Box margin={{ top: '1em' }}>
        <Form ref={formRef} data={formData} {...({} as any)}>
          <Input
            name={Pr<FormData>('name')}
            placeholder="Заголовок *"
            rules={[isRequired(), maxLength(255)]}
          />
          <TextArea
            name={Pr<FormData>('description')}
            rules={[isRequired(), maxLength(1000)]}
            placeholder="Описание *"
            rows={8}
            {...({} as any)}
          />
        </Form>
      </Box>
    );
  },
  { forwardRef: true }
);
