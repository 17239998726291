import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores';
import { ModalView, breakpoints } from 'ui';
import { ActionModalConfig } from 'stores/ActionModalsStore';
import { useMemo, useRef } from 'react';
import { observable } from 'mobx';
import { ButtonEx, Error } from 'ui';
import { Header } from './Header';
import { withTheme } from 'styled-components';
import { IStyledProps } from 'themes';
import { Box } from 'grommet';

export type TActionModalProps<T = any> = {
  config: ActionModalConfig;
  actionData: TActionDataType<T>;
  style?: any;
  ref: any;
  onClose: () => void;
  onValidate: {
    callback?: () => Promise<any>;
  };
};

export type TActionDataType<T = any> = {
  isValid: boolean;
  data?: T;
};

function isClassComponent(component: any) {
  return (
    (!!component.prototype && !!component.prototype.isReactComponent) ||
    typeof component !== 'function'
  );
}

export const ActionModal = withTheme(
  observer<
    {
      config: ActionModalConfig;
      visible: boolean;
    } & IStyledProps
  >(({ config, visible, theme }) => {
    const { actionModals } = useStores();
    const { bodyRender: BodyRender, options, id } = config;
    const actionData = useMemo(
      () =>
        observable({
          isValid: config.options.noValidation,
          data: config.options.initData || {}
        }),
      []
    );

    const bodyRef = useRef<{ onValidate?: () => Promise<any> }>();

    const onValidate: { callback?: () => Promise<any> } = useMemo(
      () => ({}),
      []
    );

    const {
      width = '600px',
      position = 'center',
      isOverlayClose,
      overlayStyle,
      modalStyle,
      actionsContainerStyle = {}
    } = options;

    const onClose = () => actionModals.close(id);

    const isActionLoading = config.actionStatus === 'fetching';

    const onApply = (data: any) => {
      config.actionStatus = 'fetching';

      return options.onApply(data).then(res => {
        config.actionStatus = 'success';

        return res;
      });
    };

    return (
      <ModalView
        width={width}
        position={position}
        onClose={onClose}
        isOverlayClose={isOverlayClose}
        overlayStyle={overlayStyle}
        modalStyle={modalStyle}
        style={{ visibility: visible ? 'visible' : 'hidden' }}
      >
        <Header
          title={options.title}
          onClose={onClose}
          pending={isActionLoading}
          //style={{ borderBottom: `1px solid ${theme.palette.Basic200}` }}
        />
        <Box pad={{ horizontal: '32px' }}>
          {!isClassComponent(BodyRender) ? (
            BodyRender({ actionData, config })
          ) : (
            <BodyRender
              actionData={actionData}
              config={config}
              ref={bodyRef}
              onClose={onClose}
              onValidate={onValidate}
            />
          )}
        </Box>
        {(options.closeText || options.applyText) && (
          <Box pad="24px 32px 32px 32px">
            {config.error && !isActionLoading && <Error error={config.error} />}
            <Box
              justify="between"
              direction="row"
              //style={{ borderTop: `1px solid ${theme.palette.Basic200}` }}
            >
              <Box
                direction="row"
                justify="end"
                align="center"
                fill={true}
                style={actionsContainerStyle}
              >
                {options.closeText && (
                  <ButtonEx
                    size="auto"
                    fontSize="16px"
                    transparent
                    style={{
                      padding: '11px 20px',
                      marginRight: 16,
                      height: 40
                    }}
                    onClick={onClose}
                    color="Black"
                    disabled={isActionLoading}
                  >
                    {options.closeText({})}
                  </ButtonEx>
                )}
                {options.applyText && (
                  <ButtonEx
                    style={{
                      padding: '12px 20px',
                      height: 40
                    }}
                    fontSize="16px"
                    size="auto"
                    {...options.applyButtonProps}
                    textRender={options.applyIcon && options.applyIcon({})}
                    onClick={(): void => {
                      if (
                        bodyRef &&
                        bodyRef.current &&
                        bodyRef.current.onValidate
                      ) {
                        bodyRef.current
                          .onValidate()
                          .then(onApply)
                          .catch((err: any) => {
                            config.actionStatus = 'error';
                            actionModals.rejectError(err);
                            throw err;
                          });
                      } else {
                        onApply(actionData.data);
                      }
                    }}
                    disabled={!actionData.isValid}
                    isLoading={isActionLoading}
                  >
                    {options.applyText({})}
                  </ButtonEx>
                )}
              </Box>
              <Box>
                {options.additionalText && <>{options.additionalText({})}</>}
              </Box>
            </Box>
          </Box>
        )}
      </ModalView>
    );
  })
);

export const ActionModals = observer(() => {
  const { actionModals } = useStores();
  return (
    <>
      {actionModals.pool.map((config, idx) => (
        <ActionModal
          key={config.id}
          config={config}
          visible={actionModals.pool.length - 1 === idx}
        />
      ))}
    </>
  );
});
