import * as React from 'react';
import { useStores } from 'stores';
import { IconButton } from 'ui/IconButton';
import { ExitSVG } from './svgComponents';
import { useMediaQuery } from 'react-responsive';
import { breakpoints, TransparentButton } from 'ui';
import styled from 'styled-components';
import { useTheme } from 'utils/hooks';

export function LogoutButton() {
  const { user } = useStores();
  const isTabletOrWider = useMediaQuery({ minWidth: breakpoints.tablet });

  const handleClick = () => {
    user.logout();
  };

  return isTabletOrWider ? (
    <WideLogoutButton onClick={handleClick} />
  ) : (
    <CompactLogoutButton onClick={handleClick} />
  );
}

interface ButtonProps {
  onClick: () => void;
}

function WideLogoutButton({ onClick }: ButtonProps) {
  const theme = useTheme();

  return (
    <TransparentButton
      style={{
        fontSize: '13px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: theme.palette.BlackTxt
      }}
      onClick={onClick}
    >
      <img src="/icons/exit-icon.svg" style={{ marginRight: '0.2em' }} />
      Выйти из системы
    </TransparentButton>
  );
}

function CompactLogoutButton({ onClick }: ButtonProps) {
  return (
    <StyledIconButton
      onClick={onClick}
      margin={{ left: '16px' }}
      renderIcon={() => <ExitSVG />}
    />
  );
}

const StyledIconButton = styled(IconButton)`
  background: none;

  & span {
    margin: 0;
  }
`;
