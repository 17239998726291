import { TRole } from 'interfaces';

export const RIGHTHOLDER_ROLES: TRole[] = [
  'REGULATOR',
  'APPLICANT',
  'VOTER'
];

export const EXTERNAL_ROLES: TRole[] = [];

export const ALL_ROLES = RIGHTHOLDER_ROLES.concat(EXTERNAL_ROLES);
