import { POLL_STATUS } from 'interfaces';

export { getConfigByKey };

type StatusBadgeConfig = {
  color: string;
  backgroundColor: string;
  content: string;
};

const configMap: Map<POLL_STATUS, StatusBadgeConfig> = createStatusConfigMap();

function getConfigByKey(key: POLL_STATUS) {
  if (configMap.has(key)) {
    return configMap.get(key);
  }

  if (process.env.NODE_ENV === 'development') {
    throw new Error(`\`PollStatusBadge\` component: unknown status \`${key}\``);
  } else {
    return FALLBACK_CONFIG;
  }
}

function createStatusConfigMap() {
  const configMap = new Map<POLL_STATUS, StatusBadgeConfig>();

  configMap.set(POLL_STATUS.NEW, {
    color: '#5d92f6',
    backgroundColor: '#E5EDFE',
    content: 'Новое'
  });

  configMap.set(POLL_STATUS.PUBLISHED, {
    color: '#0F62FE',
    backgroundColor: '#E5EDFE',
    content: 'Ожидает начала'
  });

  configMap.set(POLL_STATUS.IN_PROGRESS, {
    color: '#07B838',
    backgroundColor: '#E1FAE8',
    content: 'Идет голосование'
  });

  configMap.set(POLL_STATUS.FINISHED, {
    color: '#697077',
    backgroundColor: '#EBEDEF',
    content: 'Доступны результаты'
  });

  configMap.set(POLL_STATUS.ERROR, {
    color: '#f53c3c',
    backgroundColor: '#fddada',
    content: 'Ошибка'
  });

  return configMap;
}

const FALLBACK_CONFIG: StatusBadgeConfig = {
  color: '#FE0F48',
  backgroundColor: 'hsla(346, 99%, 60%, 0.1)',
  content: 'Неизвестный статус'
};
