import * as React from 'react';
import { BoxProps } from 'grommet';
import { Head } from 'components';
import styled from 'styled-components';

export function BaseContainer(props: React.PropsWithChildren<BoxProps>) {
  return (
    <LayoutContainer>
      <Head />
      <MainWrapper {...props} />
    </LayoutContainer>
  );
}

const LayoutContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.Background};
  min-height: 100vh;

  display: flex;
  flex-direction: column;
`;

const MainWrapper = styled.main`
  min-width: ${({ theme }) => theme.container.minWidth};

  flex-grow: 1;

  display: flex;
  flex-direction: column;
`;
