import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Box } from 'grommet';
import { MobxForm } from '@we-ui-components/form';
import { TActionModalProps } from 'components';
import { PollForm, FormData } from 'pages/CreatePoll/components/PollForm';
import { PollApiDto, PollCreateRequest } from 'interfaces/apiEntities';

type EditPollFormProps = TActionModalProps<PollApiDto>;

export const EditPollForm = observer<EditPollFormProps>(
  function EditPollForm(props, forwardedRef) {
    const formRef = React.useRef<MobxForm>();

    const handleSubmit = async () => {
      const data: FormData = await formRef.current.validateFields();
      const requestData: PollCreateRequest = {
        name: data.name,
        description: data.description,
        votingEndDate: data.votingEndDate.toISOString(),
        votingStartDate: data.votingStartDate.toISOString()
      };
      return requestData;
    };

    React.useImperativeHandle(forwardedRef, () => ({
      onValidate: handleSubmit
    }));

    return (
      <Box>
        <PollForm ref={formRef} initData={props.actionData.data} />
      </Box>
    );
  },
  { forwardRef: true }
);
