import { statusFetching } from 'we-oauth2/lib/constants/types';
import { NormalizedError } from 'services/api/errorHandler';
import * as _ from 'lodash';
import { Page } from 'interfaces';
import { computed, observable, action } from 'mobx';

type EndpointFn<T> = (params: any) => Promise<Page<T>>;

interface FetcherConstructorParams<T> {
  endpointFn: EndpointFn<T>;
  onSuccess: (params: any) => any;
  onError: (error: any) => any;
  pollingInterval?: number;
  getQueryParams?: () => Record<string, any>;
}

export class Fetcher<T> {
  @observable public status: statusFetching = 'init';
  private endpointFn: EndpointFn<T>;

  private pollingInterval?: number;
  private intervalContainer: number;
  private debouncedFetch: any;

  private lastRequest: Promise<Page<T>>;
  private getQueryParams: () => Record<string, any>;

  private onSuccess: (params: any) => any;
  private onError: (params: any) => any;

  constructor(params: FetcherConstructorParams<T>) {
    this.endpointFn = params.endpointFn;
    this.pollingInterval = params.pollingInterval;
    this.onError = params.onError;
    this.onSuccess = params.onSuccess;
    this.getQueryParams = params.getQueryParams;
  }

  @computed public get isPending() {
    return this.status === 'fetching';
  }

  public startPolling() {
    if (this.pollingInterval > 0) {
      this.intervalContainer = setInterval(() => {
        if (!this.isPending) {
          this.sendRequest({ isSilent: true });
        }
      }, this.pollingInterval);
    }
  }

  public stopPolling() {
    clearInterval(this.intervalContainer);
    this.intervalContainer = null;
  }

  public restartPolling() {
    this.stopPolling();
    this.startPolling();
  }

  @action
  public sendRequest(
    options: {
      isSilent?: boolean;
      withDebounce?: boolean;
    } = {},
  ) {
    const { isSilent = false, withDebounce = false } = options;

    if (withDebounce) {
      if (this.debouncedFetch) {
        return this.debouncedFetch();
      } else {
        this.debouncedFetch = _.debounce(this.sendRequest, 300);
        return this.debouncedFetch();
      }
    }

    if (!isSilent) {
      this.status = 'fetching';
    }

    return this.reqestHandler();
  }

  @action
  private reqestHandler() {
    const currentRequest = this.endpointFn(this.getQueryParams());
    this.lastRequest = currentRequest;

    return currentRequest
      .then((res: Page<T>): Page<T> | void => {
        if (this.lastRequest === currentRequest) {
          this.status = 'success';
          return this.onSuccess(res);
        }
      })
      .catch((error: NormalizedError) => {
        if (this.lastRequest === currentRequest) {
          this.status = 'error';
          this.onError(error);
        }
      });
  }

  clear() {
    this.stopPolling();
    this.status = 'init';
  }
}
